import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
    POST_DEMANDE_RAPPEL,
    postDemandeRappel,
} from '../../../../redux/actions/app/demandeRappel/demandeRappel.actions';
import {getLoadingEntity, getUtmSource} from '../../../../redux/selectors/ui/ui.selectors';
import Booking from './Booking';
import {
    getApporteur1Init
} from "../../../../redux/selectors/init/init.selectors";

const mapStateToProps = (state) => {
    return {
        queryRappel: `${API_URL_WS_FORMULAIRE}/besoin-d-aide`,
        loaded: getLoadingEntity(state, POST_DEMANDE_RAPPEL) === false,
        utm_source: getUtmSource(state),
        apporteur1: getApporteur1Init(state),
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postDemandeRappel,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { queryRappel } = stateProps;
    const { postDemandeRappel } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postDemandeRappel: (form) => postDemandeRappel({ query: queryRappel, form }),
    };
};

const BookingContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Booking);

export default BookingContainer;
