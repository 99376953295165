import info from '@amo/core/assets/images/icons/info.svg';
import keys from '@amo/core/assets/images/icons/keys.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';

const ConnexionClient = (props) => {
    const { postDejaClient, idDejaClient, loading, nomDejaClient } = props;

    return (
        <Question icon={keys} title={'Renseignez vos informations client APRIL&nbsp;Moto'}>
            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'tel'}
                        name={'DemandeTarif[ListePersonnes][0][NumeroClient]'}
                        placeholder={'Exemple : 123456789'}
                        label={'Numéro client'}
                    />
                </div>
            </div>

            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'tel'}
                        name={'DemandeTarif[ListePersonnes][0][Nom]'}
                        placeholder={'Exemple : Dupont'}
                        label={'Nom'}
                    />
                </div>
            </div>

            {/*<div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Date}
                        name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                        placeholder={'Exemple : 22/03/1990'}
                        label={'Date de naissance'}
                    />
                </div>
            </div>*/}

            <BulleAide
                icon={info}
                title={
                    'Vous pouvez trouver votre numéro client ou votre numéro contrat en entête des documents contractuels que l’on vous a transmis.'
                }
            />

            <button
                className={`btn btn-primary btn-arrow mt-4 ${(!idDejaClient || !nomDejaClient) && 'disabled'}`}
                onClick={() => postDejaClient({ idDejaClient, nomDejaClient })}
            >
                {loading ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                ) : (
                    'Continuer'
                )}
            </button>
        </Question>
    );
};

export default ConnexionClient;
