import contrat from '@amo/core/assets/images/AMO_Icons/Carte_Grise.svg';
import { Question } from '@amo/core/components/containers';
import {Radio, Select} from '@amo/core/components/forms';
import { Field } from 'redux-form';
import Swal from "sweetalert2";
import _ from "lodash";

export const DateSouscriptionContrat = (props) => {
    const { nextQuestion, data } = props;

    const showPopup = () => {
        Swal.fire({
            icon: 'info',
            title: 'Le saviez-vous ?',
            confirmButtonText: "J'ai bien compris",
            html: '<p>Vous êtes éligible à la loi sur la Consommation (dite loi Hamon). Si vous le souhaitez et sous réserve d’acceptation du devis, nous procéderons gratuitement aux démarches de résiliation auprès de votre assureur actuel.</p><div class="text-start mt-2"><p><b>Le saviez-vous&nbsp;?</b></p><ul><li>Les sociétés ne sont pas concernées par cette loi</li><li>Le souscripteur doit être identique pour l\'ancienne et la nouvelle assurance</li></ul></div>',
        });
    }

    return (
        <Question
            icon={contrat}
            title={`Depuis quand avez-vous <b>souscrit votre contrat</b>&nbsp;?`}
        >
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`dateSouscriptionAncienContrat`}
                        value={'0'}
                        label={'Il y a moins d\'un an'}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`dateSouscriptionAncienContrat`}
                        value={'1'}
                        label={'Il y a plus d\'un an'}
                        onChange={() => showPopup()}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${_.isEmpty(_.get(data, 'dateSouscriptionAncienContrat')) && 'disabled'}`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};
