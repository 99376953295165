import { connect } from 'react-redux';
import { CODEPROMO } from '../../../../../../redux/actions/app/tarif/tarif.actions';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import CodePromo from './CodePromo';

const mapStateToProps = (state) => {
    return {
        loading: getLoadingEntity(state, CODEPROMO),
    };
};

const CodePromoContainer = connect(mapStateToProps)(CodePromo);

export default CodePromoContainer;
