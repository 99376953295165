import {APP_ENV, APP_NAME, APP_URL_APPLICATION} from '@amo/core/constants/constants.js';
import { decrypt } from '@amo/core/utils/functions';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import {getStepBySlug} from '../../../../utils/function';
import { INIT, POST_INIT, setInit } from '../../../actions/app/init/init.actions';
import { TARIF } from '../../../actions/app/tarif/tarif.actions';
import { setError, setIndexQuestion, setLoader, setStep, setUtmSource } from '../../../actions/app/ui/ui.actions';
import { VEHICULE, getVehicule, setVehicule } from '../../../actions/app/vehicule/vehicule.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { clearLocalStorage, setLocalStorage } from '../../../actions/core/localStorage/localStorage.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const initMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        const { payload } = action;
        let nextActions = [];
        let locationSearch = queryString.parse(window.location.search);

        switch (action.type) {
            case POST_INIT:
                /* Verify expire token 1h */
                let body = payload.body;

                nextActions = [
                    apiRequest({
                        body,
                        method: 'POST',
                        url: payload.data,
                        entity: INIT,
                        otherData: { localstorage: body.key === '' },
                    }),
                    setLoader({ state: true, entity: INIT }),
                ];

                if (
                    (localStorage.getItem(APP_NAME) &&
                        moment(JSON.parse(localStorage.getItem(APP_NAME)).expire).add(1, 'hours') < moment()) ||
                    body.key
                ) {
                    nextActions.push(clearLocalStorage({ entity: INIT }));
                    body.token = null;
                }

                if (locationSearch.utm_source) {
                    nextActions.push(setUtmSource({ data: locationSearch }));
                }

                next(nextActions);
                break;

            case `${INIT} ${API_SUCCESS}`:
                const {
                    state,
                    token,
                    data: { source },
                    whitelabel,
                } = action.payload.data;
                const { localstorage } = action.payload.meta.otherData;
                let estimateInLocal = undefined;

                /* Reset URL KEY */
                if (window.location.pathname !== '/') {
                    window.location.pathname = '/';
                }

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'environnnement': APP_ENV,
                    'ap1': action.payload.data.apporteur1?.codeBelair,
                    'ap2': action.payload.data.apporteur2?.codeBelair,
                });

                nextActions.push(setLocalStorage({ entity: INIT, storage: { token } }));

                /* Ajout des options souscrites au retour du paiement */
                if (locationSearch.paid === '1' && action.payload.data?.data?.Devis) {
                    nextActions.push(
                        analyticsSetEvent({
                        event: 'purchase',
                        datas: {
                            ecommerce: {
                                currency: 'EUR',
                                value: action.payload.data.data.Devis.Formule.PrimeAnnuelleFracAnnuel,
                                transaction_id: action.payload.data.data.Contrat.Police || '-',
                                coupon: action.payload.data?.Tarif?.CodePromo?.Eligible ? action.payload.data?.DemandeTarif?.CodePromo?.CodePromo : '',
                                phoneNumber : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.TelPortable.replace(/\s/g, ''), // à voir
                                birthDate : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.DateNaissance ? moment(action.payload.data.data.DemandeTarif.ListePersonnes[0].DateNaissance, 'DD/MM/YYYY').format('YYYYMMDD') : null,
                                firstName : _.toLower(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Prenom),
                                lastName : _.toLower(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Nom),
                                street : `${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse1}${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse2 ? ` ${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse2}` : ''}`,
                                zipCode : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.CP,
                                city : _.lowerCase(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Ville).replace(/\s/g, ''),
                                items: [{
                                    item_name: `Assurance ${action.payload.data.data.Devis.Vehicule.FamilleProduit}`,
                                    price: action.payload.data.data.Devis.Formule.PrimeAnnuelleFracAnnuel,
                                    item_category: action.payload.data.data.Devis.Vehicule.FamilleProduit,
                                    item_variant: action.payload.data.data.DemandeTarif.Police.FormuleChoisie,
                                    quantity: '1'
                                }]
                            }
                        }
                    })
                    )
                }

                if (
                    payload.data.data.DemandeTarif &&
                    payload.data.data.DemandeTarif.Vehicule &&
                    payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA
                ) {
                    nextActions.push(setLoader({ state: true, entity: VEHICULE }));
                }

                /* Récupération des données depuis le localStorage s'il y en a */
                if (localstorage) {
                    if (window.localStorage.estimate) {
                        estimateInLocal = JSON.parse(decrypt(window.localStorage.estimate));
                    }
                }

                let init = _.cloneDeep(payload.data);
                let cloneInit = init;

                if (estimateInLocal) {
                    cloneInit.data = { ...cloneInit.data, ...estimateInLocal.data };
                    cloneInit.question = 0;
                    cloneInit.step = estimateInLocal.step;
                }

                /* si la source n'est pas le direct on remplace les variables par le WhiteLabel */
                const couleurSite = source === 'site' && payload.data.apporteur.codeBelair === '43397';

                if (!couleurSite) {
                    for (let [key, value] of Object.entries(whitelabel)) {
                        if (_.startsWith(key, 'style_color')) {
                            document.documentElement.style.setProperty(`--${key}`, value);
                        }
                    }
                }

                /* END Récupération des données depuis le localStorage s'il y en a END */
                if (cloneInit.data.DemandeTarif.Vehicule?.IdentifiantSRA) {
                    dispatch(getVehicule({ idSra: cloneInit.data.DemandeTarif.Vehicule.IdentifiantSRA }));
                } else {
                    dispatch(
                        setVehicule({
                            data: {
                                data: {
                                    cylindree: cloneInit.data.cylindreeVehicule,
                                    brand: { name: cloneInit.data.marqueVehicule },
                                    type: { id: cloneInit.data.typeVehicule },
                                },
                            },
                        }),
                    );
                }

                let step = 0;
                switch (state) {
                    /* Nouveau devis */
                    case 'new':
                        nextActions = nextActions.concat([
                            setInit({ init }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    /* Etape de tarif avant paiement */
                    case 'tarif':
                    case 'devis':
                    case 'devis_orange':
                        const souscripteur = _.find(payload.data.data.DemandeTarif.ListePersonnes, {
                            Souscripteur: true,
                        });
                        const souscripteurIsMineur =
                            souscripteur &&
                            souscripteur.DateNaissance &&
                            moment(souscripteur.DateNaissance).isAfter(moment().subtract(18, 'years'));

                        nextActions = nextActions.concat([
                            // On redirige sur la 1ere étape pour les comparateurs et les données manquantes
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);

                        if (souscripteurIsMineur) {
                            nextActions.push(setStep({ step: 0 }));
                            nextActions.push(setIndexQuestion({ index: 0 }));
                        } else {
                            step = getStepBySlug('votre-tarif');
                            nextActions.push(setStep({ step: getStepBySlug('votre-tarif') }));
                        }

                        break;

                    /* save_devis fait, en attente du paiement */
                    case 'contrat_en_attente_de_paiement':
                        step = getStepBySlug('paiement');
                        nextActions = nextActions.concat([
                            setStep({ step: getStepBySlug('paiement') }),
                            setInit({ init: payload.data }),
                            setLoader({ state: false, entity: TARIF }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    /* retour du paiement en attente de la validation pour signature */
                    case 'contrat_en_cours_de_validation':
                    case 'contrat':
                        step = getStepBySlug('validation');
                        nextActions = nextActions.concat([
                            setStep({ step: getStepBySlug('validation') }),
                            setInit({ init: payload.data }),
                            setError({ state: false, entity: INIT }),
                            setLoader({ state: false, entity: INIT }),
                        ]);
                        break;

                    default:
                        return null;
                }

                if (
                    payload.data.data.DemandeTarif &&
                    payload.data.data.DemandeTarif.Vehicule &&
                    payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA
                ) {
                    dispatch(getVehicule({ idSra: payload.data.data.DemandeTarif.Vehicule.IdentifiantSRA }));
                } else {
                    nextActions.push(setLoader({ state: false, entity: VEHICULE }));
                }

                next(nextActions);

                break;

            case `${INIT} ${API_ERROR}`:
                nextActions = [setError({ state: true, entity: INIT }), setLoader({ state: true, entity: INIT })];

                if (action.payload.data.response !== undefined && action.payload?.data?.response?.data?.code === 404) {
                    nextActions.push(
                        setNotification({
                            entity: INIT,
                            html: "Votre devis n'a pas pu être retrouvé (expiration, suppression...). Vous pouvez dès à présent refaire une proposition d'assurance !",
                            title: 'Attention !',
                            icon: 'error',
                            confirmButtonText: "J'ai compris",
                            allowOutsideClick: false,
                            successFunction: () => {
                                window.localStorage.clear();
                                window.location.reload();
                                window.location.href = APP_URL_APPLICATION;
                            },
                        }),
                    );
                } else if (action.payload.data.response !== undefined) {
                    nextActions.push(
                        setNotification({
                            entity: INIT,
                            html: action.payload?.data?.response?.data?.error,
                            title: 'Attention !',
                            icon: 'error',
                            confirmButtonText: "J'ai compris",
                        }),
                    );
                }

                next(nextActions);
                break;

            default:
                return null;
        }
    };
