import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';

const TiersPayeurType = (props) => {
    const { data, changeValue } = props;

    useEffect(() => {
        if (_.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'M') {
            changeValue('DemandeContrat[TiersPayeur][BirthName]', '');
            changeValue('DemandeContrat[TiersPayeur][FirstName]', '');
            changeValue('DemandeContrat[TiersPayeur][BirthDate]', '');
            changeValue('DemandeContrat[TiersPayeur][BirthCountry]', '');
            changeValue('DemandeContrat[TiersPayeur][BirthCity]', '');
            changeValue('DemandeContrat[TiersPayeur][Relationship]', '');
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '');
        }
    }, [_.get(data, 'DemandeContrat[TiersPayeur][Type]')]);

    useEffect(() => {
        if (!_.get(data, 'DemandeContrat[TiersPayeur][Type]')) {
            changeValue('DemandeContrat[TiersPayeur][Type]', 'P');
        }
    }, []);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={`DemandeContrat[TiersPayeur][Type]`}
                    value={'P'}
                    label={'Personne physique'}
                    className={'small'}
                />
            </div>
            <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                <Field
                    component={Radio}
                    type={'radio'}
                    name={`DemandeContrat[TiersPayeur][Type]`}
                    value={'M'}
                    label={'Personne morale'}
                    className={'small'}
                />
            </div>
        </div>
    );
};

export default TiersPayeurType;
