import stars from '@amo/core/assets/images/icons/stars.svg';
import april from '@amo/core/assets/images/logos/april.svg';
import amazon from '@amo/core/assets/images/logos/landing/amazon.png';
import leboncoin from '@amo/core/assets/images/logos/landing/leboncoin.png';
import lrdm from '@amo/core/assets/images/logos/landing/lrdm.png';
import aprilWhite from '@amo/core/assets/images/logos/april-white.svg';
import labelExcellence from '../../../../../../assets/images/footer/label_excellence_2023.png';
import mastercardVisa from '../../../../../../assets/images/landing/mastercard-visa-blanc.svg';

const HeaderLanding = (props) => {
    const { nameWhitelabel, utm_source } = props;
    const logos = { lbc: leboncoin, amazon: amazon, lrdm: lrdm };

    const utmSourceForNewHeader = {
        stvalentin: {
            title: `L'assurance moto <br /> qui prend soin de votre passion`
        },
        lbc: {
            title: `Vous êtes au meilleur endroit <br /> pour assurer votre deux-roues&nbsp;!`,
            logo: leboncoin
        }
    }

    if (utmSourceForNewHeader.hasOwnProperty(utm_source)) {
        return (
            <>
                <div className={`w-full header-landing-container header-${utm_source} position-relative mb-5`}>
                    <div className='position-relative container'>
                        <div className="label-excellence">
                            <img src={labelExcellence} alt="Label Excellence" className={'img-fluid p-3'} width={150}  />
                        </div>

                        <div className="logo-container">
                            <div className="logo-marque">
                                <img src={aprilWhite} alt="April" className={'img-fluid'} width={150} />
                                { utmSourceForNewHeader[utm_source].logo && <img src={utmSourceForNewHeader[utm_source].logo} alt="April" className={'img-fluid bg-white py-3 px-2 rounded'} width={150} /> }
                                {
                                    utm_source === 'stvalentin' &&
                                    <img src={lrdm} alt="La route des motards" className={'img-fluid logo-lrdm'}
                                         width={150}/>
                                }
                            </div>

                            <div className="logo-paiement">
                                <div
                                    className="bg-white rounded-circle d-flex align-items-center justify-content-center"
                                    style={{height: 36, width: 36}}>
                                    <span className="icon-locker" style={{fontSize: 20}}/>
                                </div>

                                <div className="d-flex flex-column">
                                    <span className="text-white f-14">Paiement sécurisé</span>
                                    <img src={mastercardVisa} alt="Mastercard Visa" className={'img-fluid'} width={50}/>
                                </div>

                                {
                                    utm_source !== 'stvalentin' &&
                                    <img src={lrdm} alt="La route des motards" className={'img-fluid logo-lrdm'}
                                         width={150}/>
                                }
                            </div>
                        </div>

                        <p className={'text-white f-14'}>
                        Bonjour, bienvenue chez <span className={'fw-bold'}>{nameWhitelabel}.</span>
                        </p>
                        <p className={'text-white mb-3 f-14'}>
                            Comme vous, <span className={'fw-bold'}>500 000 motards</span> nous ont déjà fait
                            confiance.
                        </p>
                        <p className={'header-landing-title'} dangerouslySetInnerHTML={{__html: utmSourceForNewHeader[utm_source].title}} />

                        <p className={'text-white f-14 mt-3'}>
                            <b>96%</b> de nos assurés sont <b>satisfaits</b>
                        </p>

                        <p className='text-white f-14 d-flex gap-2 mt-1'>
                            <b style={{ lineHeight: '17px' }}>4,3/5</b>
                            <img src={stars} alt="Note" className={'img-star'} />
                        </p>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={'container-fluid'}>
            <div className={'row bg-grey'}>
                <div
                    className={`bg-blue rounded-bottom-medium pt-3 p-5 header-tarif ${
                        utm_source === 'lrdm' ? 'header-lrdm' : 'text-center'
                    } header-landing position-relative`}
                >
                    {utm_source === 'lrdm' ? (
                        <>
                            <p className={'text-white pt-lg-5 mt-lg-5 mt-md-4 mt-2 mx-lg-5 mx-md-4 mx-2 f-16'}>
                                Bonjour, bienvenue chez <span className={'fw-bold'}>{nameWhitelabel}.</span>
                            </p>
                            <p className={'text-white mb-3 mx-lg-5 mx-md-4 mx-2 f-16'}>
                                Comme vous, <span className={'fw-bold'}>500 000 motards</span> nous ont déjà fait
                                confiance.
                            </p>
                            <p className={'text-white f-40 fw-bold mx-lg-5 mx-md-4 mx-2 my-lg-3 my-md-2 my-1'}>
                                Vous êtes au meilleur endroit <br />
                                pour assurer votre véhicule&nbsp;!
                            </p>

                            {/*<img src={promo_mars} alt={'30€ de réduction'} className={'img-promo img-fluid mx-lg-5 mx-md-4 mx-2 my-lg-3 my-md-2 my-1'}/>*/}
                            <div className={'mx-lg-5 mx-md-4 mx-2 logos-partenaires'}></div>
                        </>
                    ) : (
                        <>
                            <p className={'text-white f-32 fw-bold mx-5 mt-5 mb-3 pt-lg-5'}>
                                Vous êtes au meilleur endroit <br />
                                pour assurer votre <span className={'text-dark'}>2-roues</span>...{' '}
                            </p>
                            <p className={'text-white'}>
                                Bonjour, bienvenue chez <span className={'fw-bold'}>{nameWhitelabel}.</span>
                            </p>
                            <p className={'text-white mb-3'}>
                                Comme vous, <span className={'fw-bold'}>500 000 motards</span> nous ont déjà fait
                                confiance.
                            </p>
                            <p className={'text-white'}>
                                Dans 3 clics, vous aurez{' '}
                                <span className={'fw-bold text-dark'}>votre attestation d'assurance&nbsp;!</span>
                            </p>

                            <div className={'px-4 py-3 bg-white mt-3 rounded position-absolute bloc-landing shadow'}>
                                <img src={logos[utm_source] || april} alt="Logo" className={'pt-2'} width={100} />
                            </div>

                            <p className={'text-white f-16 mt-3'}>
                                <b>96%</b> de nos assurés sont <b>satisfaits</b>
                            </p>

                            <p
                                className={
                                    'text-white f-16 d-flex justify-content-center align-items-center gap-2 mt-2'
                                }
                            >
                                <b style={{ lineHeight: 1 }}>4,3/5</b>
                                <img src={stars} alt="Note" className={'img-star'} />
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HeaderLanding;
