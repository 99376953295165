import Depanneuse from '@amo/core/assets/images/AMO_Icons/Depanneuse.svg';
import Protection from '@amo/core/assets/images/AMO_Icons/Protection.svg';
import SearchVehicule from '@amo/core/assets/images/AMO_Icons/SearchVehicule.svg';
import ToutMotards from '@amo/core/assets/images/AMO_Icons/Tout-motards.svg';
import Indemnisation from '@amo/core/assets/images/AMO_Icons/indemnisation.svg';

export const getTextOptions = (codeOption, apporteur1) => {
    switch (codeOption) {
        case 'ASS' /* Assistance */:
            return `<p>Ne soyez pas bloqué à cause d’une panne. <br/> Choisissez ${
                apporteur1 === 43397 ? 'l’une de nos options d’assistance' : 'notre option d’assistance'
            } et reprenez la route.</p><br/><p><b class="text-dark les_plus"><span>Les + ${
                apporteur1 === 43397 ? 'APRIL Moto :' : ''
            }</span></b>Avec le <b>Service e-dépanneuse</b>, géolocalisez et suivez en temps réel la dépanneuse qui vous vient en aide.<br/>Bénéficiez de prestations d'assistance tout confort 24H/24&nbsp;et&nbsp;7J/7.</p>`;
        case 'PCC' /* Protection corporelle du conducteur */:
            return `<p>Protégez-vous et vos proches en cas d’accident responsable. <br/> Mieux vaut être préparé à tous les cas de figure.</p><br/><p><b class="text-dark les_plus"><span>Les + ${
                apporteur1 === 43397 ? 'APRIL Moto :' : ''
            }</span></b>Et quel que soit votre taux d'invalidité, nous prenons en charge vos frais médicaux et votre perte de revenus, jusqu'à 1 500 € ! Si vous êtes équipé d'un <b>gilet airbag</b> au moment du sinistre, ces <b>plafonds d'indemnisation seront doublés.</b></p>`;
        case 'ACCESSEQP' /* Accessoires et équipements */:
            return `Sans eux, vous ne pouvez pas prendre la route. <br/> Pensez à assurer vos accessoires et équipements.`;
        case 'KSM' /* Casse mécanique */:
            return `<p>Ne bricolez plus pour économiser. <br/> Nous prenons en charge vos frais de réparation si vous tombez en panne.</p><br/><p><b class="text-dark les_plus"><span>Les + ${
                apporteur1 === 43397 ? 'APRIL Moto :' : ''
            }</span></b>Les frais de réparation sont pris en charge pendant les 10 premières années du véhicule.</p>`;
        case 'VALMAJ' /* Valeur majorée */:
            return `<p>Votre véhicule est volé ou irréparable, augmentez votre remboursement !</p><br/><p><b class="text-dark les_plus"><span>Les + ${
                apporteur1 === 43397 ? 'APRIL Moto :' : ''
            }</span></b>Nous vous remboursons votre 2-roues :<ul><li>à sa valeur d'achat jusqu'à 24 mois selon la date de 1ère mise en circulation : aucune décôte</li><li>à sa valeur à dire d'expert majorée de 15% au-delà des 24 mois (avec en plus un minimum garanti !)</li></ul>
        `;
        default:
            return null;
    }
};

export const getIconOptions = (codeOption) => {
    switch (codeOption) {
        case 'ASS' /* Assistance */:
            return Depanneuse;
        case 'PCC' /* Protection corporelle du conducteur */:
            return Protection;
        case 'ACCESSEQP' /* Accessoires et équipements */:
            return ToutMotards;
        case 'KSM' /* Casse mécanique */:
            return SearchVehicule;
        case 'VALMAJ' /* Valeur majorée */:
            return Indemnisation;
        default:
            return null;
    }
};
