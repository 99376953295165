import info from '@amo/core/assets/images/icons/card.svg';
import { BulleAide } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { maxDate33Days } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Field } from 'redux-form';

const DateHeureEffetHamon = (props) => {
    const { formuleChoisie, fractionnementChoisi, changeValue } = props;

    useEffect(() => {
        changeValue(
            'DemandeContrat[DateHeureEffet]',
            moment().add(33, 'days').add(5, 'minutes').format('DD/MM/YYYY HH:mm'),
        );
    }, []);

    return (
        <>
            <div className={'row justify-content-center'}>
                <h3 className={'f-16 text-center fw-bold mt-5'}>Quand votre contrat pourra-t-il débuter ?</h3>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2 text-start'}>
                    <Field
                        name={`DemandeContrat[DateHeureEffet]`}
                        label={`Le nouveau contrat pourra commencer au plus tôt le :`}
                        component={Text}
                        type={'text'}
                        typeFormat={'dateHour'}
                        placeholder={'Exemple : 22/03/2023 12:20'}
                        maxLength={16}
                        validate={maxDate33Days}
                        disabled
                        required
                    />
                </div>
            </div>

            <BulleAide
                text={`Nous avons besoin de ce délai pour pouvoir résilier votre contrat actuel en toute simplicité.<br/>
                        Pas d'inquiétude, vous serez protégé(e) jusqu'à ce que votre nouveau contrat soit opérationnel.`}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12 mb-5 mt-3'}
            />

            <BulleAide
                icon={info}
                title={'Un justificatif d\'assurance dès la validation du paiement'}
                text={`Vous allez payer <strong>${
                    fractionnementChoisi === 'M'
                        ? _.round(formuleChoisie.ComptantMensuelPromo, 2)
                        : _.round(formuleChoisie.ComptantAnnuelPromo, 2)
                }€ TTC</strong> par carte bancaire <b>correspondant ${
                    fractionnementChoisi === 'M' ? 'à vos deux premières mensualités' : 'à votre première année'
                }</b>. Une fois ce paiement effectué, cela va déclencher l’envoi d’un justificatif d'assurance. Votre véhicule sera intégré sous 72h au FVA (Fichier des Véhicules Assurés).`}
                className={'mt-0 col-lg-8 offset-lg-2 col-md-12 mt-3'}
            />
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2 text-start'}>
                    {fractionnementChoisi === 'M' ? (
                        <small>
                            A la fin de vos deux premiers mois, vous serez prélevé tous les mois de{' '}
                            {_.round(formuleChoisie.PrimeMensuellePromo, 2)}€ TTC.
                        </small>
                    ) : (
                        <small>
                            A la fin de votre première année, vous serez prélevé tous les ans de{' '}
                            {_.round(formuleChoisie.ComptantAnnuel, 2)}€ TTC.
                        </small>
                    )}
                </div>
            </div>
        </>
    );
};

export default DateHeureEffetHamon;
