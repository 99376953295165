import fingers from '@amo/core/assets/images/icons/fingers.png';
import { getStepBySlug } from '../../../../../utils/function';
import _ from "lodash";

const Paiement = (props) => {
    const { goTo, postPaySubmit, vehicule, analyticsSetEvent, data } = props;

    const myPostPaySubmit = () => {
        analyticsSetEvent({event: 'quoteConfirmation'})
        postPaySubmit();
    };

    return (
        <div id={'etape-cancel'}>
            <div className={'row justify-content-center align-items-center mt-5'}>
                <div className={'col-lg-3 col-md-6 col-sm-16 col-12 my-2'}>
                    <img src={fingers} alt={'Confirmation devis'} />
                </div>
                <div className={'col-lg-7 col-md-6 col-sm-16 col-12 my-2 text-start text-dark'}>
                    <h2 className={'my-4 f-30 fw-bold'}>Votre souscription est en attente de paiement.</h2>
                    <p className={'f-14 mb-5'}>
                        Vous êtes à deux pas de finaliser la souscription pour votre véhicule{' '}
                        <b>
                            {vehicule.brand?.name} {vehicule.cylindree}
                        </b>
                    </p>

                    <button
                        className={`btn btn-secondary btn-smaller btn-arrow mb-2`}
                        onClick={() => goTo({ step: getStepBySlug('recapitulatif'), question: 0 })}
                    >
                        Retour au récapitulatif
                    </button>
                    <button className={`btn btn-primary btn-smaller btn-arrow ms-4`} onClick={() => myPostPaySubmit()}>
                        Je finalise ma souscription
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Paiement;
