import fingers from '@amo/core/assets/images/icons/fingers.png';

const EnvoiPieces = (props) => {
    const { documentsUrl } = props;

    return (
        <div id={'etape-documents'}>
            <div className={'row justify-content-center align-items-center mt-5'}>
                <div className={'col-lg-3 offset-lg-1 col-md-6 col-sm-16 col-6 my-2'}>
                    <img src={fingers} alt={'Confirmation devis'} className={'img-fluid'} />
                </div>
                <div className={'col-lg-7 col-md-6 col-sm-16 col-12 my-2 text-lg-start text-dark text-center'}>
                    <h2 className={'my-4 f-22 fw-bold'}>
                        Bravo, vous y êtes presque,
                        <br />
                        plus qu’une petite étape...
                    </h2>
                    <p className={'f-16 mb-5'}>
                        Il ne nous manque plus que vos documents <br />
                        justificatifs pour compléter et finaliser votre dossier !
                    </p>
                    <a href={documentsUrl} className={`btn btn-primary btn-smaller btn-arrow`}>
                        Envoyer vos documents
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EnvoiPieces;
