import Chat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import { Question } from '@amo/core/components/containers';

const GoToAntecedents = (props) => {
    const { nextQuestion } = props;

    return (
        <Question icon={Chat} title={'<b>Parfait</b>, nous en savons désormais plus sur vous !'}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'}>
                        Nous allons maintenant vous poser quelques questions
                        <br /> concernant <b>vos antécédents routiers.</b>
                        <br />
                        <br />
                        Prêt à y répondre ?{' '}
                    </p>
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                C'est parti !
            </button>
        </Question>
    );
};

export default GoToAntecedents;
