import EmailClient from './EmailClient/EmailClient';
import NumeroTelContainer from './NumeroTel/NumeroTelContainer';

const Coordonnees = (props) => {
    const { id } = props;

    return (
        <div id={id}>
            <EmailClient {...props} />
            <NumeroTelContainer {...props} />
        </div>
    );
};

export default Coordonnees;
