import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { FILL } from '../../../../../../redux/actions/app/fill/fill.actions';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import NumeroTel from './NumeroTel';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-direct');

    return {
        loadingPostFill: getLoadingEntity(state, FILL),
        needCaptcha:
            selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]') ===
            selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]'),
    };
};

const NumeroTelContainer = connect(mapStateToProps)(NumeroTel);

export default NumeroTelContainer;
