import Chat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import { Question } from '@amo/core/components/containers';

const GoToProfil = (props) => {
    const { nextQuestion } = props;

    return (
        <Question
            icon={Chat}
            title={"<b>C'est bon</b>, nous avons tout ce qu'il nous faut sur <b>votre véhicule</b> 🙂"}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'}>
                        Si on passait à vous maintenant ?<br /> Nous avons quelques questions à vous poser !
                    </p>
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                Allons-y !
            </button>
        </Question>
    );
};

export default GoToProfil;
