import { Text } from '@amo/core/components/forms';
import { maxDateToday, min14Year } from '@amo/core/utils/validateField.js';
import { Field } from 'redux-form';

const TiersPayeurBirthDate = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={'DemandeContrat[TiersPayeur][BirthDate]'}
                    component={Text}
                    label={'Date de naissance'}
                    type={'tel'}
                    typeFormat={'date'}
                    placeholder={'Exemple : 22/03/1990'}
                    validate={[maxDateToday, min14Year]}
                />
            </div>
        </div>
    );
};

export default TiersPayeurBirthDate;
