export const ANALYTICS = '[Analytics]';
export const SET_ANALYTICS = `${ANALYTICS} Set`;
export const ANALYTICS_SET_EVENT = `${ANALYTICS} Set Event`;

export const analyticsSetEvent = ({ event, datas = null }) => ({
    type: ANALYTICS_SET_EVENT,
    meta: {
        analytics: {
            event,
            datas
        },
    },
});

export const setAnalytics = ({ entity }) => ({
    type: `${entity} ${SET_ANALYTICS}`,
    payload: {
        entity,
    },
});

export const setTrackPage = ({ page }) => ({
    type: `${ANALYTICS} ${page}`,
    meta: {
        entity: ANALYTICS,
        analytics: {
            event: 'Page View',
            actionGoogle: page,
            pageMatomo: page,
        },
    },
});
