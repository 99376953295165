import { getRoutesByDevice } from '@amo/core/utils/getRoutesByDevice.js';
import { connect } from 'react-redux';
import {
    getApporteur1Init,
    getApporteur2Init,
    getLogoWhitelabel,
    getNameWhitelabel,
} from '../../../../redux/selectors/init/init.selectors';
import { getIndexQuestion, getStep, getUtmSource } from '../../../../redux/selectors/ui/ui.selectors';
import { routesAffichees } from '../../../../utils/function';
import RouteStep from '../RouteStep';
import Header from './Header';

const mapStateToProps = (state) => {
    return {
        logo: getLogoWhitelabel(state),
        indexQuestion: getIndexQuestion(state),
        step: getStep(state),
        routes: routesAffichees(getRoutesByDevice(getStep(state), RouteStep)),
        nameWhitelabel: getNameWhitelabel(state),
        apporteur2: getApporteur2Init(state),
        apporteur1: getApporteur1Init(state),
        utm_source: getUtmSource(state),
    };
};

const HeaderContainer = connect(mapStateToProps)(Header);

export default HeaderContainer;
