export const DEJA_CLIENT = '[Deja Client]';

export const POST_DEJA_CLIENT = `${DEJA_CLIENT} Post`;

export const postDejaClient = ({ query, data, nextQuestion }) => ({
    type: POST_DEJA_CLIENT,
    payload: {
        data: query,
        body: data,
        successFunction: nextQuestion,
    },
});
