import info from '@amo/core/assets/images/AMO_Icons/Searching.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { LargeSpinner } from '@amo/core/components/loaders';
import { minDateHourToday } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    NumeroSerie,
    ImmatriculationAndCheckbox,
    ImmatOuMarquage,
    Immatriculation
} from '@amo/core/components/questions';
import getFormuleChoisie from '../Tarif/utilsTarif/getFormuleChoisie';
import AncienAssureur from './AncienAssureur/AncienAssureur';
import DateHeureEffet from './DateHeureEffet/DateHeureEffet';
import DateHeureEffetHamon from './DateHeureEffetHamon/DateHeureEffetHamon';
import Iban from './Iban/Iban';
import LoiHamon from './LoiHamon/LoiHamon';
import NumeroContrat from './NumeroContrat/NumeroContrat';
import NumeroPermis from './NumeroPermis/NumeroPermis';
import RecapPersonne from './RecapPersonne/RecapPersonne';
import RecapTarif from './RecapTarif/RecapTarif';
import RecapVehicule from './RecapVehicule/RecapVehicule';
import TiersPayeurBirthCity from './TiersPayeur/TiersPayeurBirthCity/TiersPayeurBirthCity';
import TiersPayeurBirthCountry from './TiersPayeur/TiersPayeurBirthCountry/TiersPayeurBirthCountry';
import TiersPayeurBirthDate from './TiersPayeur/TiersPayeurBirthDate/TiersPayeurBirthDate';
import TiersPayeurBirthName from './TiersPayeur/TiersPayeurBirthName/TiersPayeurBirthName';
import TiersPayeurFirstName from './TiersPayeur/TiersPayeurFirstName/TiersPayeurFirstName';
import TiersPayeurLastName from './TiersPayeur/TiersPayeurLastName/TiersPayeurLastName';
import TiersPayeurRelationship from './TiersPayeur/TiersPayeurRelationship/TiersPayeurRelationship';
import TiersPayeurThirdPayerReason from './TiersPayeur/TiersPayeurThirdPayerReason/TiersPayeurThirdPayerReason';
import TiersPayeurType from './TiersPayeur/TiersPayeurType/TiersPayeurType';
import TitulaireCompte from './TitulaireCompte/TitulaireCompte';
import {Field} from "redux-form";
import {Checkbox} from "@amo/core/components/forms/index.js";

const Recapitulatif = (props) => {
    const {
        data,
        postPaySubmit,
        formules,
        payLoaded,
        nomTitulaireCg,
        nameWhitelabel,
        nomPrenom,
        changeValue,
        isLoadedTarif,
        queryTarif,
        postReloadTarif,
        souscripteurIsSociety,
        analyticsSetEvent,
        nomSouscripteur
    } = props;
    const formuleChoisie = getFormuleChoisie(data, formules);
    const [tryPost, setTryPost] = useState(false);

    useEffect(() => {
        if (tryPost) {
            isNotValid();
        }
    }, [data, tryPost]);

    useEffect(() => {
        changeValue('DemandeContrat[NomTitulaire]', nomPrenom);
        if (!isLoadedTarif) {
            postReloadTarif({ form: data, query: queryTarif });
        }
    }, []);

    /* Function qui ajoute la class error sur les champs vide */
    const handleError = (fieldName, remove) => {
        _.forEach(document.getElementsByName(fieldName), (input) => {
            if (input.getAttribute('type') === 'radio') {
                if (remove) {
                    input.labels[0].classList.remove('error');
                } else {
                    input.labels[0].classList.add('error');
                }
            } else {
                if (remove) {
                    input.classList.remove('error');
                } else {
                    input.classList.add('error');
                }
            }
        });
    };

    const isNotValid = () => {
        let notValid = false;
        let fields = [
            `DemandeTarif[ListePersonnes][0][NumPermis]`,
            _.get(data, 'immatriculation_check') === '0'
                ? `DemandeTarif[Vehicule][VIN]`
                : `DemandeTarif[Vehicule][Immatriculation]`,
            `DemandeContrat[DevisHamon]`,
            `DemandeContrat[DateHeureEffet]`,
            `DemandeContrat[IBAN]`,
            `DemandeContrat[BIC]`,
        ];

        if (
            _.get(data, 'DemandeContrat[NomTitulaire]') &&
            _.toUpper(_.trim(_.get(data, 'DemandeContrat[NomTitulaire]'))) !== _.toUpper(nomTitulaireCg)
        ) {
            fields = [
                ...fields,
                `DemandeContrat[TiersPayeur][Type]`,
                `DemandeContrat[TiersPayeur][LastName]`,
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthName]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][FirstName]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthCountry]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthCity]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][Relationship]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthDate]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P' &&
                _.get(data, 'DemandeContrat[TiersPayeur][Relationship]') === 'A'
                    ? `DemandeContrat[TiersPayeur][ThirdPayerReason]`
                    : ''
            ];
        }

        if (_.get(data, "DemandeContrat[DevisHamon]") === "1" && !_.get(data, "dont-know-contract-number")){
            fields = [...fields, 'DemandeContrat[DevisHamonContratActuel]']
        } else {
            handleError('DemandeContrat[DevisHamonContratActuel]', true)
        }

        if (_.get(data, "DemandeContrat[DevisHamon]") === "1"){
            fields = [...fields, 'confirm-souscripteur']
            fields = [...fields, 'DemandeContrat[DevisHamonAssureurActuel]']
        } else {
            handleError('confirm-souscripteur', true)
            handleError('DemandeContrat[DevisHamonAssureurActuel]', true)
        }

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
                notValid = true;
            } else {
                handleError(field, true);
            }
            if (field === 'DemandeContrat[BIC]' && !_.get(data, field)) {
                document.getElementById('btn-check-iban').style.color = 'red';
                document.getElementById('btn-check-iban').style.borderColor = 'red';
                document.getElementById('btn-check-iban').style.backgroundColor = 'transparent';
            }
        });

        if (
            !moment(_.get(data, 'DemandeContrat[DateHeureEffet]'), 'DD/MM/YYYY HH:mm', true).isValid() ||
            minDateHourToday(_.get(data, 'DemandeContrat[DateHeureEffet]'))
        ) {
            handleError('DemandeContrat[DateHeureEffet]');
            notValid = true;
        }

        return notValid;
    };

    const myPostPaySubmit = () => {
        setTryPost(true);
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            analyticsSetEvent({event: 'quoteConfirmation'})
            postPaySubmit();
        }
    };

    return (
        <>
            {isLoadedTarif ? (
                <>
                    {payLoaded ? (
                        <LargeSpinner message={'Vous allez être redirigé vers le site de la banque'} />
                    ) : (
                        <div id={'etape-recap'}>
                            <Question loader icon={info} title={'Récapitulatif'}>
                                <RecapVehicule {...props} />

                                <RecapPersonne {...props} />

                                <RecapTarif {...props} formuleChoisie={formuleChoisie} />

                                <h3 className={'f-16 text-center fw-bold mt-5'}>Informations Complémentaires</h3>

                                <NumeroPermis {...props} />

                                {_.get(data, 'immatriculation_check') === '0' ? (
                                    <NumeroSerie {...props} />
                                ) : (
                                    <Immatriculation {...props} />
                                )}

                                <ImmatOuMarquage {...props} hasBulle/>

                                {((_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]') > 11 ||
                                    _.includes(['50', 'quad', 'verte'], data?.typeVehicule)) && !souscripteurIsSociety && _.get(data, 'titulaireCarteGrise') !== "2") && (
                                    <>
                                        <p className="question-title mx-2 mt-5" data-title="Récapitulatif">Résiliation contrat : Loi Hamon</p>
                                        <LoiHamon {...props} />
                                    </>
                                )}

                                {((_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]') > 11 ||
                                    _.includes(['50', 'quad', 'verte'], data?.typeVehicule)) &&
                                    !souscripteurIsSociety) &&
                                    _.get(data, 'DemandeContrat[DevisHamon]') === '1' && (
                                        <>
                                            <div className="row justify-content-center">
                                                <div className={'col-12 col-lg-8 mb-2 mt-3'}>
                                                    <Field name="confirm-souscripteur" component={Checkbox}>
                                                        <p className={"cursor-pointer"}>{`J'atteste que le souscripteur du contrat actuel est bien ${nomSouscripteur}`}<sup
                                                            className="text-danger">*</sup></p>
                                                    </Field>
                                                </div>
                                            </div>

                                            <AncienAssureur {...props} />

                                            <NumeroContrat {...props} />
                                        </>
                                    )}

                                {((_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]') > 11 ||
                                        _.includes(['50', 'quad', 'verte'], data?.typeVehicule)) &&
                                    !souscripteurIsSociety) && _.get(data, 'DemandeContrat[DevisHamon]') === '1' ? (
                                    <DateHeureEffetHamon {...props} formuleChoisie={formuleChoisie} />
                                ) : (
                                    <DateHeureEffet {...props} formuleChoisie={formuleChoisie} />
                                )}

                                <h3 className={'f-16 text-center fw-bold mt-4'}>Mes informations de prélèvement</h3>

                                <TitulaireCompte nomTitulaireCg={nomTitulaireCg} {...props} />

                                <Iban {...props} />

                                {_.get(data, 'DemandeContrat[NomTitulaire]') &&
                                    _.toUpper(_.trim(_.get(data, 'DemandeContrat[NomTitulaire]'))) !==
                                        _.toUpper(nomTitulaireCg) && (
                                        <>
                                            <h3 className={'f-16 text-center fw-bold mt-5'}>Tiers payeur</h3>

                                            <BulleAide
                                                icon={info}
                                                title={'Pourquoi demandons-nous les informations du tiers payeur ?'}
                                                text={`La personne qui va payer les cotisations n'étant pas présente sur le contrat, nous avons besoin d'avoir ses informations personnelles.`}
                                                className={'mt-0 col-lg-8 offset-lg-2 col-md-12'}
                                            />

                                            <TiersPayeurType {...props} />

                                            <TiersPayeurLastName
                                                tiersPayeurType={_.get(data, 'DemandeContrat[TiersPayeur][Type]')}
                                                {...props}
                                            />

                                            {_.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P' && (
                                                <>
                                                    <TiersPayeurBirthName {...props} />

                                                    <TiersPayeurFirstName {...props} />

                                                    <TiersPayeurBirthDate {...props} />

                                                    <TiersPayeurBirthCountry {...props} />

                                                    <TiersPayeurBirthCity {...props} />

                                                    <TiersPayeurRelationship {...props} />

                                                    {_.get(data, 'DemandeContrat[TiersPayeur][Relationship]') ===
                                                        'A' && <TiersPayeurThirdPayerReason {...props} />}
                                                </>
                                            )}
                                        </>
                                    )}
                            </Question>

                            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => myPostPaySubmit()}>
                                Souscrire
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <LargeSpinner message={'Nous rechargeons votre tarif !'} />
            )}
        </>
    );
};

export default Recapitulatif;
