import { getStepBySlug } from '../../../../utils/function';
import { FETCH_CHECK_STATUT_CONTRAT } from '../../../actions/app/checkContratStatut/checkContratStatut.actions';
import { setInit } from '../../../actions/app/init/init.actions';
import { setError, setLoader, setStep } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';

export const checkContratStatutMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case FETCH_CHECK_STATUT_CONTRAT:
            next([
                apiRequest({ body: null, method: 'POST', url: payload.data, entity: FETCH_CHECK_STATUT_CONTRAT }),
                setLoader({ state: true, entity: FETCH_CHECK_STATUT_CONTRAT }),
            ]);

            break;

        case `${FETCH_CHECK_STATUT_CONTRAT} ${API_SUCCESS}`:
            if (payload.data.state === 'contrat') {
                /* Vérifie qu'une signature est en cours */
                const step =
                    payload.data.signatures.length > 0 &&
                    payload.data.signatures[0].refused === false &&
                    payload.data.signatures[0].signed === false &&
                    payload.data.signatures[0].started === false
                        ? getStepBySlug('signature')
                        : getStepBySlug('envoi-des-pieces');

                next([
                    setInit({ init: payload.data }),
                    setStep({ step: step }),
                    setError({ state: false, entity: FETCH_CHECK_STATUT_CONTRAT }),
                    setLoader({ state: false, entity: FETCH_CHECK_STATUT_CONTRAT }),
                ]);
            } else {
                next([
                    setError({ state: false, entity: FETCH_CHECK_STATUT_CONTRAT }),
                    setLoader({ state: false, entity: FETCH_CHECK_STATUT_CONTRAT }),
                ]);
            }

            break;

        case `${FETCH_CHECK_STATUT_CONTRAT} ${API_ERROR}`:
            next([
                setError({ state: true, entity: FETCH_CHECK_STATUT_CONTRAT }),
                setLoader({ state: false, entity: FETCH_CHECK_STATUT_CONTRAT }),
            ]);
            break;

        default:
            return null;
    }
};
