import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { appMiddleware } from '../middlewares/app';
import { coreMiddleware } from '../middlewares/core';
import AppReducer from './AppReducer';
import {APP_ENV} from "@amo/core/constants/index.js";

const middlewares = [...appMiddleware, ...coreMiddleware];

if (APP_ENV !== 'production') {
    middlewares.push(logger);
}

export const store = createStore(AppReducer, applyMiddleware(...middlewares));
