import chevron from '@amo/core/assets/images/icons/chevron-left-white.svg';
import { normalizeTel } from '@amo/core/utils/normalize.js';
import { getStepBySlug } from '../../../../utils/function';
import NavBar from './NavBar';
import FlecheGauche from '../../../../assets/images/icone-fleche-gauche-grise.png'

const Header = (props) => {
    const {
        previousQuestion,
        indexQuestion,
        step,
        nbQuestions,
        routes,
        setShowBooking,
        logo,
        nameWhitelabel,
        apporteur2,
        utm_source,
        apporteur1,
        analyticsSetEvent
    } = props;
    let tel;

    const handleResetAndRedirect = () => {
        localStorage.clear();
        window.location = '/'
    };

    const telApporteur2 = {
        67256: '0279834142',
        100122: '0219011492',
        101853: '0219011491',
        393798: '0279834145',
    };

    if (apporteur1.codeBelair === '809466') {
        tel = '0487937213';
    } else if (apporteur2) {
        tel = telApporteur2[apporteur2.codeBelair] || (utm_source === 'lrdm' ? '0219011493' : '0219011494');
    } else if (utm_source === 'april') {
        tel = '0255453459';
    } else {
        tel = utm_source === 'lrdm' ? '0219011493' : '0219011494';
    }

    return (
        <div className={'header f-12'}>
            <div className={'py-lg-4 px-lg-5 px-2 py-2'}>
                <div className={'container-fluid'}>
                    <div
                        className={`row ${
                            step !== getStepBySlug('confirmation-devis')
                                ? 'justify-content-between'
                                : 'justify-content-center'
                        } align-items-center`}
                    >
                        {(indexQuestion === 0 && step === 0 && step <= 7) ||
                        step === getStepBySlug('confirmation-devis') ? null : (
                            <div className="col-3 d-lg-none d-xl-none">
                                <span className={'btn btn-return-small me-3'} onClick={() => previousQuestion()}>
                                    <img src={chevron} alt="<" width={12} />
                                </span>
                            </div>
                        )}

                        {step !== getStepBySlug('confirmation-devis') && (
                            <div className={'col-xl-2 d-none d-lg-block text-center'}>
                                {(indexQuestion === 0 && step === 0 && step <= 7) ||
                                step === getStepBySlug('recontact') ? null : (
                                    <span
                                        className={`btn btn-return btn-smaller  me-3`}
                                        onClick={() => previousQuestion()}
                                    >
                                        <img src={FlecheGauche} alt="Question précédente" style={{width: 20, marginRight: 8}}/>Question précédente
                                    </span>
                                )}
                            </div>
                        )}

                        <div className="col-4 col-md-2 col-xl-1 text-end">
                            <img src={logo} alt={nameWhitelabel} style={{maxWidth: 115, width: "100%"}}/>
                        </div>

                        {step !== getStepBySlug('confirmation-devis') && (
                            <div className={`d-none d-lg-block col-xl-6 text-center`}>
                                <NavBar
                                    nbQuestions={nbQuestions}
                                    routes={routes}
                                    activeStep={step}
                                    indexQuestion={indexQuestion}
                                />
                            </div>
                        )}

                        <div className="d-none d-lg-block col-xl-3 text-center">
                            {step <= 7 && step !== getStepBySlug('confirmation-devis') && (
                                <>
                                    <button className={'btn btn-outline-primary btn-phone'}
                                            onClick={() => {
                                                analyticsSetEvent({
                                                    event: 'clickToCall',
                                                    datas: {'clickToCall': {'number': tel}}
                                                })
                                                document.location.href = `tel:${tel}`
                                            }}>
                                        <span className={'icon'}></span>
                                        <small>Un conseiller vous aide</small>
                                        <span>{normalizeTel(tel)}</span>
                                    </button>
                                    <span
                                        className={'d-block text-secondary f-13 mt-1 fw-bolder cursor-pointer'}
                                        onClick={() => setShowBooking(true)}
                                    >
                                        <small>ou</small> <b className={'underline-link'}>rappelez-moi</b>
                                    </span>
                                    {apporteur1.codeBelair === '43397' && (
                                        <small className={'f-10'}>
                                            Déjà client ? contactez le{' '}
                                            <button
                                                className={'text-grey text-decoration-none btn btn-link f-10 p-0 m-0 align-baseline border-0'}
                                                onClick={() => {
                                                    analyticsSetEvent({
                                                        event: 'clickToCall',
                                                        datas: {'clickToCall': {'number': '0247510707'}}
                                                    })
                                                    document.location.href = `tel:0247510707`
                                                }}>
                                                <b>02 47 51 07 07</b>
                                            </button>
                                        </small>
                                    )}
                                    <br/>
                                    <div className={'underline-link mt-2 cursor-pointer d-inline-block'}
                                         onClick={() => handleResetAndRedirect()}>
                                        <b>Recommencer le devis</b>
                                    </div>
                                </>
                            )}
                        </div>

                        {step <= 7 && step !== getStepBySlug('confirmation-devis') && (
                            <div className="d-block d-lg-none d-xl-none col-3 text-end">
                                <span
                                    className={'btn btn-outline-primary btn-phone-small'}
                                    onClick={() => setShowBooking(true)}
                                >
                                    &nbsp;
                                </span>
                            </div>
                        )}

                        {step !== getStepBySlug('confirmation-devis') && (
                            <div className="d-block d-lg-none d-xl-none col-12 text-center mt-4">
                                <NavBar
                                    nbQuestions={nbQuestions}
                                    routes={routes}
                                    activeStep={step}
                                    indexQuestion={indexQuestion}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
