import { getIconByType } from '@amo/core/utils/functions';
import _ from 'lodash';

const RecapVehicule = (props) => {
    const { data, goTo, vehicule } = props;
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <div className={'p-3 bg-white rounded border'}>
                    <div className={'row'}>
                        <div className="col-2">
                            <img src={getIconByType(data?.typeVehicule)} alt="Véhicule" className={'pt-2'} width={40} />
                        </div>

                        <div className="col-10 text-start">
                            <p className={'f-16 text-dark text-start fw-bold'}>
                                Votre véhicule{' '}
                                <span
                                    className={'text-secondary f-12 float-end cursor-pointer underline-link'}
                                    onClick={() => goTo({ step: 0, question: 1 })}
                                >
                                    modifier
                                </span>
                            </p>
                            <small className={'d-block f-13'}>
                                <span className={'text-dark fw-bold'}>
                                    {vehicule.brand?.name} {vehicule.cylindree}
                                </span>
                                <br />
                                {vehicule.modele}{' '}
                                {`${
                                    vehicule.annee_debut_version !== null && vehicule.annee_debut_version !== 0
                                        ? `depuis ${vehicule.annee_debut_version}`
                                        : ''
                                }
                                 ${
                                     vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
                                         ? ` jusqu'à ${vehicule.annee_fin_version}`
                                         : ''
                                 }`}
                                <br />
                                Stationnement à {_.get(data, 'DemandeTarif[Vehicule][VilleGarage]')}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecapVehicule;
