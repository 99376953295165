import {Checkbox, Text} from '@amo/core/components/forms';
import { Field } from 'redux-form';
import Swal from "sweetalert2";
import _ from "lodash";
import {useEffect} from "react";

const NumeroContrat = (props) => {
    const { data, changeValue } = props

    const showPopup = e => {
        if(e.currentTarget.value === "false"){
            changeValue('DemandeContrat[DevisHamonContratActuel]', "")
            Swal.fire({
                icon: 'info',
                text: "Soyez tranquille, un conseiller va venir vers vous dans les plus brefs délais pour prendre en charge les démarches de résiliation de votre assurance.",
                confirmButtonText: "J'ai bien compris"
            });
        }
    }

    return (
        <>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>
                    <Field
                        name={`DemandeContrat[DevisHamonContratActuel]`}
                        label={`Votre numéro de contrat actuel`}
                        component={Text}
                        type={'text'}
                        help={"Toutes ces informations sont présentes sur votre avis d'échéance !"}
                        required={!_.get(data, "dont-know-contract-number")}
                        disabled={_.get(data, "dont-know-contract-number")}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className={'col-12 col-lg-8 my-2'}>
                    <Field name="dont-know-contract-number" component={Checkbox} onChange={e => showPopup(e)}>
                        <p className={"cursor-pointer"}>{`Je ne connais pas le numéro de mon contrat actuel`}</p>
                    </Field>
                </div>
            </div>
        </>
    );
};

export default NumeroContrat;
