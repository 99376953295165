import Antecedents from './Step/Antecedents/Antecedents';
import Besoins from './Step/Besoins/Besoins';
import ConfirmationDevisContainer from './Step/ConfirmationDevis/ConfirmationDevisContainer';
import Coordonnees from './Step/Coordonnees/Coordonnees';
import EnvoiPiecesContainer from './Step/EnvoiPieces/EnvoiPiecesContainer';
import InformationsSouscripteurContainer from './Step/InformationsSouscripteur/InformationsSouscripteurContainer';
import RecontactContainer from './Step/InformationsSouscripteur/RecontactContainer';
import PaiementContainer from './Step/Paiement/PaiementContainer';
import Profil from './Step/Profil/Profil';
import RecapitulatifContainer from './Step/Recapitulatif/RecapitulatifContainer';
import SignatureContainer from './Step/Signature/SignatureContainer';
import TarifContainer from './Step/Tarif/TarifContainer';
import ValidationContainer from './Step/Validation/ValidationContainer';
import Vehicule from './Step/Vehicule/Vehicule';

let step = 0;

const RouteStep = [
    {
        step: step++,
        slug: 'votre-vehicule',
        title: 'Votre véhicule',
        main: ({ props, other }) => <Vehicule {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'votre-profil',
        title: 'Votre profil',
        main: ({ props, other }) => <Profil {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-antecedents',
        title: 'Vos antécédents',
        main: ({ props, other }) => <Antecedents {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-besoins',
        title: 'Vos besoins',
        main: ({ props, other }) => <Besoins {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-coordonnees',
        title: 'Vos coordonnées',
        main: ({ props, other }) => <Coordonnees {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'votre-tarif',
        title: 'Votre tarif',
        main: ({ props, other }) => <TarifContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'vos-infos',
        title: 'Vos informations',
        main: ({ props, other }) => <InformationsSouscripteurContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'recapitulatif',
        title: 'Votre récapitulatif',
        main: ({ props, other }) => <RecapitulatifContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'paiement',
        title: 'Paiement',
        main: ({ props, other }) => <PaiementContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'validation',
        title: 'Validation',
        main: ({ props, other }) => <ValidationContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'signature',
        title: 'Signature',
        main: ({ props, other }) => <SignatureContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'envoi-des-pieces',
        title: 'Envoi des pièces',
        main: ({ props, other }) => <EnvoiPiecesContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'recontact',
        title: 'Etre recontacté',
        main: ({ props, other }) => <RecontactContainer {...props} {...other} />,
    },
    {
        step: step++,
        slug: 'confirmation-devis',
        title: 'Confirmation de la proposition',
        main: ({ props, other }) => <ConfirmationDevisContainer {...props} {...other} />,
    },
];

export default RouteStep;
