import { Text } from '@amo/core/components/forms';
import { validatePhone } from '@amo/core/utils/validateField';
import { Field } from 'redux-form';

const NumeroTel = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    label={'N° de téléphone mobile'}
                    component={Text}
                    type={'tel'}
                    typeFormat={'tel'}
                    name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                    placeholder={'Exemple : 02 38 53 02 38'}
                    validate={[validatePhone]}
                    required
                />
                <small className={'text-start d-block'}>
                    Ce numéro de téléphone sera utilisé dans le cadre de la gestion de votre contrat.
                </small>
            </div>
        </div>
    );
};

export default NumeroTel;
