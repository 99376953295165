import tchat from '@amo/core/assets/images/AMO_Icons/Star.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

const CrmMoto50Plus3Ans = (props) => {
    const { nextQuestion, data } = props;

    return (
        <Question icon={tchat} title={'Avez vous ce <b>bonus moto</b> de 0.50 depuis <b>plus de 3 ans</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}
                        value={'1'}
                        label={'Oui'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]'}
                        value={'0'}
                        label={'Non'}
                        onClick={() => nextQuestion(300)}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    !_.get(data, 'DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]') && 'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default CrmMoto50Plus3Ans;
