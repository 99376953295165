import zarco from '@amo/core/assets/images/icons/zarco.png';
import moment from 'moment';
import Calendrier from './Calendrier/Calendrier';

const Creneaux = (props) => {
    const { setShowBooking, data, postDemandeRappel, apporteur1 } = props;

    const submitBooking = () => {
        const formData = {};
        const creneauSuivant = moment(data.booking_heureChoisi.split('+')[0], 'HH:mm')
            .add(30, 'minutes')
            .format('HH:mm');

        formData.nom = data.booking_nom;
        formData.prenom = data.booking_prenom;
        formData.tel = data.booking_tel;
        formData.heure = data.booking_heureChoisi.split('+')[0] + ' - ' + creneauSuivant;
        formData.date = data.booking_heureChoisi.split('+')[1];
        formData.asap = false;
        formData.ap1 = `${apporteur1?.codeBelair}`;

        postDemandeRappel(formData);
        setShowBooking(false);
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className={'col-12 mb-4'}>
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-4 col-lg-2 text-center'}>
                            <img src={zarco} alt={'être rappelé'} />
                        </div>
                        <div className={'col-8 col-lg-5 text-start'}>
                            <h2 className={'text-dark f-24 fw-bold mt-4 mb-2'}>Vous souhaitez être rappelé ?</h2>
                            <h3 className={'text-dark f-18 fw-bold mb-4'}>Sélectionnez votre créneau</h3>
                        </div>
                    </div>
                </div>
            </div>

            <Calendrier {...props} />

            <span
                className={`cursor-pointer btn btn-primary btn-arrow btn-smaller mb-2`}
                onClick={() => {
                    submitBooking();
                }}
            >
                Prendre rendez-vous
            </span>
        </>
    );
};

export default Creneaux;
