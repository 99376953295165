import { APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import { connect } from 'react-redux';
import {
    getApporteur1Init,
    getIDDevisDevisInit,
    getUrlSignaturesInit
} from '../../../../../redux/selectors/init/init.selectors';
import Signature from './Signature';

const mapStateToProps = (state) => {
    return {
        url: `${getUrlSignaturesInit(state)}?url_return=${APP_URL_APPLICATION}`,
        idDevis: getIDDevisDevisInit(state),
        apporteur1: getApporteur1Init(state)
    };
};

const SignatureContainer = connect(mapStateToProps)(Signature);

export default SignatureContainer;
