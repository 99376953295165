import React, {useEffect, useRef} from 'react';
import TrustPilot from '../../../../../../assets/images/logo_trustpilot.png'
import {useOnClickOutside} from "@amo/core/utils/hooks.js";
const TrustBox = ({ setShowPopin }) => {
    const ref = useRef(null);
    const contentRef = useRef(null);
    useOnClickOutside(contentRef, () => setShowPopin(false))

    //todo ajouter le data-template-id et décommenter la ref et le useEffect pour afficher le trustpilot directement
    // Information dans le compte TrustPilot Business
    // https://support.trustpilot.com/hc/fr/articles/203840826-Ajouter-un-widget-TrustBox-pour-les-avis-marchands

    useEffect(() => {
        //if (window.Trustpilot) {
        //    window.Trustpilot.loadFromElement(ref.current, true);
        //}
    }, []);

    return (
        <div className={"text-center"}>
            <div style={{background: 'rgba(0,0,0,.4)', zIndex: 90}} className={'position-fixed bottom-0 top-0 start-0 end-0 p-2'} >
                <div className={'bg-white p-4 pb-5 mt-5 position-relative'} style={{borderRadius: 20, boxShadow: '0px 14px 50px rgb(0 0 0 / 15%)', width: '54em', maxWidth: '100%', margin: '0 auto'}} ref={contentRef}>
                    <img src={TrustPilot} alt="Trustpilot" width={300}/>

                    <p className={'fw-bold my-4'}>Racontez-nous votre expérience APRIL Moto en donnant votre avis sur Trustpilot.</p>

                    <span className={'btn-close cursor-pointer position-absolute'} style={{top: 20, right: 20}} onClick={() => setShowPopin(false)}/>

                    <a
                        //ref={ref}
                        href="https://fr.trustpilot.com/evaluate/april-moto.com"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={"btn btn-primary"}
                        onClick={() => setShowPopin(false)}
                    > J'évalue mon expérience APRIL Moto </a>
                </div>
            </div>
        </div>
    );
};
export default TrustBox;