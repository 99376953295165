import card from '@amo/core/assets/images/icons/card.svg';

const RecapTarif = (props) => {
    const {
        goTo,
        formuleChoisie
    } = props;

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <div className={'p-3 bg-white rounded border'}>
                    <div className={'row'}>
                        <div className="col-2">
                            <img src={card} alt="Informations tarif" className={'pt-2'} />
                        </div>

                        <div className="col-10 text-start">
                            <p className={'f-16 text-dark text-start fw-bold'}>
                                Votre formule{' '}
                                <span
                                    className={'text-secondary f-12 float-end cursor-pointer underline-link'}
                                    onClick={() => goTo({ step: 5, question: 0 })}
                                >
                                    modifier
                                </span>
                            </p>
                            {formuleChoisie?.LibelleFormule && (
                                <small className={'d-block f-13 text-dark fw-bold'}>
                                    Formule {formuleChoisie.LibelleFormule}
                                </small>
                            )}
                            <small className={'d-block f-13'}>+ options</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecapTarif;
