import { Select } from '@amo/core/components/forms';
import { getCompanys } from '@amo/core/utils/requestsApi.js';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';

const AncienAssureur = () => {
    const [companys, setCompanys] = useState([]);

    useEffect(() => {
        (async function fetchCompanys() {
            let listCompanys = await getCompanys();
            setCompanys(listCompanys);
        })();
    }, []);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 my-2'}>
                <Field
                    label={'Votre assurance actuelle'}
                    help={"Toutes ces informations sont présentes sur votre avis d'échéance !"}
                    name={'DemandeContrat[DevisHamonAssureurActuel]'}
                    component={Select}
                    required
                >
                    <option value={''}>Sélectionnez votre assureur actuel</option>
                    {companys.map((item, index) => {
                        return (
                            <option key={index} value={`${item.code}|${item.libelle}`}>
                                {item.libelle}
                            </option>
                        );
                    })}
                </Field>
            </div>
        </div>
    );
};

export default AncienAssureur;
