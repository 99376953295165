import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { INIT, postInit } from '../../redux/actions/app/init/init.actions';
import { VEHICULE } from '../../redux/actions/app/vehicule/vehicule.actions';
import { localStorageAMO } from '../../redux/selectors/localStorage/localStorage.selectors';
import { getLoadingEntity } from '../../redux/selectors/ui/ui.selectors';
import InitByDevisWs from './InitByDevisWs';

const mapStateToProps = (state) => {
    return {
        loaded: getLoadingEntity(state, INIT) === false && getLoadingEntity(state, VEHICULE) === false,
        state,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postInit: () =>
                postInit({
                    token: localStorageAMO && localStorageAMO.token,
                    key: window.location.pathname.slice(1),
                    origin: 'direct',
                }),
        },
        dispatch,
    );

const InitByDevisWsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(InitByDevisWs);

export default InitByDevisWsContainer;
