import {useEffect, useMemo, useState} from 'react';
import {getAddress} from "../../../utils/index.js";
import _ from "lodash";
import {Text} from "../Text/Text.jsx";
import {Checkbox} from "../Checkbox/Checkbox.jsx";
import {Field} from "redux-form";
import { required } from '@amo/core/utils/validateField';

export const CAPAdresse = (props) => {
    const { setHasSearchAddress, data, changeValue } = props

    const [listAddresses, setListAddresses] = useState([]);
    const [addressSelected, setAddressSelected] = useState(null);
    const [localityId, setLocalityId] = useState(null);

    const DemandeTarifPath = 'DemandeTarif[ListePersonnes][0]';
    const ville = useMemo(() => _.get(data, `${DemandeTarifPath}[Ville]`), [data]);
    const codePostal = useMemo(() => _.get(data, `${DemandeTarifPath}[CP]`), [data]);

    useEffect(() => {
        if (ville && codePostal) {
            setAddressSelected(ville + ' ' + codePostal);
            setHasSearchAddress(true);
        }
    }, [ville, codePostal]);

    useEffect(() => {
        if(_.get(data, `${DemandeTarifPath}[Ville]`) && _.get(data, `${DemandeTarifPath}[CP]`)){
            fetchAddress(`${_.get(data, `${DemandeTarifPath}[Ville]`)} ${_.get(data, `${DemandeTarifPath}[CP]`)}`, 'city')
        }
    }, [_.get(data, `${DemandeTarifPath}[Ville]`), _.get(data, `${DemandeTarifPath}[CP]`)]);

    const fetchAddress = async (value, where) => {
        setAddressSelected(null);
        let addressParams;
        if (where === 'city') {
            addressParams = [value, 'Undefined'];
        } else {
            addressParams = [
                value,
                'Undefined',
                localityId,
                _.get(data, `${DemandeTarifPath}[CP]`),
                _.get(data, `${DemandeTarifPath}[Ville]`)
            ];
        }
        let address = await getAddress(...addressParams);
        //SERVICES en panne

        if (address.status === 500) {
            changeValue('addressNotFound', true);
            const element = document.getElementById('addressNotFound');
            if (element) {
                element.style.display = 'none';
            }
        } else {
            if (where === 'city') {
                if (address && address.length > 0) {
                    setLocalityId(address[0].localityId);
                } else {
                    setLocalityId(null);
                }
                setListAddresses([]);
                ['Adresse1', 'Adresse2', 'Adresse3'].forEach(field => changeValue(`${DemandeTarifPath}[${field}]`, null));
            } else {
                setListAddresses(address?.slice(0, 15));
            }
            setHasSearchAddress(true);
        }
    }

    const selectAddr = (address) => {
        setAddressSelected(address);
        changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', address.additionalInfo_1);
        changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', '');
        changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', address.buildingName || '');
    };

    return (
        <>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mt-3 mb-2'}>
                <Field
                    name={`DemandeTarif[ListePersonnes][0][Adresse1]`}
                    label={'Adresse'}
                    component={Text}
                    type={'text'}
                    placeholder={'Exemple : ENTREE F BATIMENT 5 37 BIS RUE DE LA GARE 75011 PARIS'}
                    onChange={(e) =>
                        e.target.value.length > 5 && !_.get(data, 'addressNotFound')
                            ? fetchAddress(e.target.value, 'address')
                            : null
                    }
                    validate={required}
                    required
                />
                {!_.get(data, 'addressNotFound') &&
                    !addressSelected &&
                    _.map(listAddresses, (adresse, i) => (
                        <div key={i} onClick={() => selectAddr(adresse)} className={'list-group'}>
                            <p className={'text-start cursor-pointer m-0 list-group-item list-group-item-action'}>
                                {adresse.inputOutput}
                            </p>
                        </div>
                    ))}
            </div>
            <div className={'row justify-content-center'} id={'addressNotFound'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-4 text-start'}>
                    <Field name="addressNotFound" component={Checkbox}>
                        <p className={'mt-1'}>Je ne trouve pas mon adresse</p>
                    </Field>
                </div>
            </div>
            {(addressSelected || _.get(data, 'addressNotFound')) && (
                <>
                    <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            component={Text}
                            type={'text'}
                            name={'DemandeTarif[ListePersonnes][0][Adresse2]'}
                            label={"Avez-vous un complément d'adresse ?"}
                            disabled={addressSelected && !_.get(data, 'addressNotFound')}
                        />
                    </div>
                    <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            component={Text}
                            type={'text'}
                            name={'DemandeTarif[ListePersonnes][0][Adresse3]'}
                            normalize={(v) => v.toUpperCase()}
                            label={'Bâtiment, escalier, étages'}
                            disabled={addressSelected && !_.get(data, 'addressNotFound')}
                        />
                    </div>
                </>
            )}
        </>
    );
};