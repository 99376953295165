import _ from 'lodash';
import { change } from 'redux-form';
import { POST_DEJA_CLIENT } from '../../../actions/app/dejaClient/dejaClient.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import { formatDataForApi } from '../fill/formatDataForApi';
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const dejaClientMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case POST_DEJA_CLIENT:
            const body = formatDataForApi(payload.body);

            next([
                apiRequest({
                    body,
                    method: 'POST',
                    url: payload.data,
                    entity: POST_DEJA_CLIENT,
                    otherData: payload.successFunction,
                }),
                setLoader({ state: true, entity: POST_DEJA_CLIENT }),
            ]);

            break;

        case `${POST_DEJA_CLIENT} ${API_SUCCESS}`:
            let nextAction = [
                setError({ state: false, entity: POST_DEJA_CLIENT }),
                setLoader({ state: false, entity: POST_DEJA_CLIENT }),
            ];

            if(action.payload.data.data.DemandeTarif.ListePersonnes[0].DejaClient) {
                _.forEach(payload.data.data.DemandeTarif.ListePersonnes[0], (value, key) => {
                    nextAction.unshift(change('devis-direct', `DemandeTarif.ListePersonnes.0.${key}`, value));
                });

                nextAction.unshift(
                    setNotification({
                        entity: POST_DEJA_CLIENT,
                        html: '<div><p>Nous sommes heureux de vous revoir&nbsp;!</p></div>',
                        icon: 'success',
                        title: `Bonjour ${_.get(payload.data.data.DemandeTarif, 'ListePersonnes.0.Nom')} ${
                            _.get(payload.data.data.DemandeTarif, 'ListePersonnes.0.Prenom') || ''
                        }&nbsp;!`,
                        confirmButtonText: 'Je suis bien identifé',
                        successFunction: payload.meta.otherData,
                    }),
                );
            } else {
                nextAction.unshift(analyticsSetEvent({event: 'errorMessage', datas: {
                    'errorMessage': {'label': 'Impossible de vous identifier'}
                }}))
                nextAction.unshift(change('devis-direct', `DemandeTarif.ListePersonnes.0.NumeroClient`, ''))
                nextAction.unshift(setNotification({
                    entity: POST_DEJA_CLIENT,
                    html: '<div><p>Impossible de vous identifier</p></div>',
                    icon: 'error',
                    title: 'Erreur !',
                    confirmButtonText: "J'ai compris",
                }))
            }
            next(nextAction);
            break;

        case `${POST_DEJA_CLIENT} ${API_ERROR}`:
            next([
                analyticsSetEvent({event: 'errorMessage', datas: {
                    'errorMessage': {'label': 'Impossible de vous identifier'}
                }}),
                setNotification({
                    entity: POST_DEJA_CLIENT,
                    html: '<div><p>Impossible de vous identifier</p></div>',
                    icon: 'error',
                    title: 'Erreur !',
                    confirmButtonText: "J'ai compris",
                }),
                setError({ state: true, entity: POST_DEJA_CLIENT }),
                setLoader({ state: false, entity: POST_DEJA_CLIENT }),
            ]);
            break;

        default:
            return null;
    }
};
