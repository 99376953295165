import { Text } from '@amo/core/components/forms';
import { onError, validateEmail } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';

const Email = (props) => {
    const { data, changeValue } = props;

    useEffect(() => {
        changeValue(
            'emailOnError',
            _.get(data, 'DemandeTarif[ListePersonnes][0][Email]') &&
                _.get(data, 'EmailValidation') &&
                _.get(data, 'DemandeTarif[ListePersonnes][0][Email]') !== _.get(data, 'EmailValidation')
                ? 'onError'
                : null,
        );
    }, [_.get(data, 'DemandeTarif[ListePersonnes][0][Email]'), _.get(data, 'EmailValidation')]);

    return (
        <div>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                    <Field
                        label={'Adresse email'}
                        component={Text}
                        type={'email'}
                        typeFormat={'email'}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        validate={validateEmail}
                        onPaste={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        required
                    />
                    <small className={'text-start d-block'}>Votre devis vous sera envoyé à cette adresse</small>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                    <Field component={Text} name={'emailOnError'} type={'hidden'} validate={onError}/>

                    {_.get(data, 'emailOnError') && _.get(data, 'emailOnError') === 'onError' && (
                        <small className="alert alert-danger invalid-feedback d-block">
                            Les deux adresses emails doivent être identiques.
                        </small>
                    )}

                    <Field
                        label={'Confirmer votre adresse email'}
                        component={Text}
                        type={'email'}
                        typeFormat={'email'}
                        name={'EmailValidation'}
                        validate={validateEmail}
                        onPaste={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        onCopy={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        required
                    />
                </div>
            </div>
        </div>
    );
};

export default Email;
