import { Checkbox } from '@amo/core/components/forms';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';

const ImmatOuMarquage = (props) => {
    const { data } = props;
    const date = _.get(data, 'DemandeTarif[Vehicule][DateMEC]');
    const dateMEC = moment(date, 'DD/MM/YYYY').add(30, 'days') < moment();

    return (
        <>
            {_.includes(['quad', 'verte'], data?.typeVehicule) && dateMEC && (
                <div className={'row justify-content-center'}>
                    <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-4 text-start'}>
                        <Field
                            name="immatriculation_check"
                            component={Checkbox}
                            format={(v) => v === '0'}
                            normalize={(v) => (v ? '0' : '1')}
                        >
                            <p className={'mt-1'}>Le véhicule n'a pas encore d'immatriculation</p>
                        </Field>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImmatOuMarquage;
