import bracketBottom from '@amo/core/assets/images/icons/bracket-bottom.svg';
import { BlocOption } from '@amo/core/components/group-form';
import _ from 'lodash';
import { useState } from 'react';
import { getIconOptions, getTextOptions } from './getTextOptions';
import './options.scss';

const Options = (props) => {
    const { options, fractionnementChoisi, changeValue, data, codePromo, apporteur1, analyticsSetEvent } = props;
    const [height, setHeight] = useState(
        options.filter((element) => element && (Array.isArray(element) ? element.length : true)).length > 3
            ? 400
            : 'none',
    );

    return (
        <div id={'liste-options'}>
            <div className={`row mt-4 ${height !== 'none' && 'bg-gradient-bottom'}`} style={{ maxHeight: height }}>
                <p className={'f-20 text-dark fw-bold text-center mb-3 col-12 title-option my-lg-4'}>
                    Complétez votre couverture avec des options sur-mesure&nbsp;:
                </p>

                {_.map(options, (option, index) => {
                    if (!option.labelGroupe) {
                        return null;
                    }
                    const sousInput =
                        option.data[0].listeTarifOption && option.idGroupe === 'ACCESSEQP'
                            ? option.data[0].listeTarifOption
                            : false;
                    return (
                        <BlocOption
                            idGroupe={option.idGroupe}
                            key={index}
                            icon={getIconOptions(option.idGroupe)}
                            title={option.labelGroupe}
                            fractionnement={fractionnementChoisi}
                            name={sousInput ? `optionsListe[${option.idGroupe}]` : `options[${option.idGroupe}]`}
                            data={sousInput ? sousInput : option.data}
                            changeValue={changeValue}
                            selected={_.get(
                                data,
                                sousInput ? `optionsListe[${option.idGroupe}]` : `options[${option.idGroupe}]`,
                            )}
                            text={getTextOptions(option.idGroupe, apporteur1.codeBelair)}
                            codePromo={codePromo}
                        />
                    );
                })}
            </div>

            <div className="my-5">
                {height !== 'none' ? (
                    <span className={'btn btn-outline-primary fw-normal px-3 py-2'}
                          onClick={() => {
                        analyticsSetEvent({event: 'optionsTarif', datas: {
                            'optionsTarif' : {'action' : 'Voir plus d\'options' }
                        }})
                        setHeight('none')
                    }}>
                        Voir plus d'options <img src={bracketBottom} alt=">" />
                    </span>
                ) : options.filter((element) => element && (Array.isArray(element) ? element.length : true)).length >
                  3 ? (
                    <span
                        className={'btn btn-outline-primary fw-normal px-3 py-2'}
                        onClick={() => {
                            analyticsSetEvent({event: 'optionsTarif', datas: {
                                'optionsTarif' : {'action' : 'Voir moins d\'options' }
                            }})
                            setHeight(400);
                            document.getElementById('liste-options').scrollIntoView({ behavior: 'smooth' });
                        }}
                    >
                        Voir moins d'options
                    </span>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Options;
