import { POST_DEMANDE_RAPPEL } from '../../../actions/app/demandeRappel/demandeRappel.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const demandeRappelMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case POST_DEMANDE_RAPPEL:
            next([
                apiRequest({ body: payload.body, method: 'POST', url: payload.data, entity: POST_DEMANDE_RAPPEL }),
                setLoader({ state: true, entity: POST_DEMANDE_RAPPEL }),
            ]);

            break;

        case `${POST_DEMANDE_RAPPEL} ${API_SUCCESS}`:
            next([
                analyticsSetEvent({event: 'callbackConfirmation'}),
                setNotification({
                    entity: POST_DEMANDE_RAPPEL,
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Terminer',
                    title: 'Merci, un conseiller va vous rappeler.',
                }),
                setError({ state: false, entity: POST_DEMANDE_RAPPEL }),
                setLoader({ state: false, entity: POST_DEMANDE_RAPPEL }),
            ]);
            break;

        case `${POST_DEMANDE_RAPPEL} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DEMANDE_RAPPEL,
                    body: `<p>Une erreur est survenue.</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0',
                }),
                setError({ state: true, entity: POST_DEMANDE_RAPPEL }),
                setLoader({ state: false, entity: POST_DEMANDE_RAPPEL }),
            ]);
            break;

        default:
            return null;
    }
};
