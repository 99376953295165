import Informations from '@amo/core/assets/images/icons/informations.png';
import Inclus from '@amo/core/assets/images/icons/valide.png';
import lesfurets from '@amo/core/assets/images/logos/landing/100122.png';
import lelynx from '@amo/core/assets/images/logos/landing/101853.png';
import meilleureassurance from '@amo/core/assets/images/logos/landing/393798.png';
import assurland from '@amo/core/assets/images/logos/landing/67256.png';
import { RadioBtn } from '@amo/core/components/forms';
import { getIconByType, showModal } from '@amo/core/utils/functions';
import { normalizeEuro } from '@amo/core/utils/normalize';
import { Field } from 'redux-form';
import CodePromoContainer from '../CodePromo/CodePromoContainer';
import Franchises from '../Franchises/Franchises';
import Options from '../Options/Options';
import SouscrireContainer from '../Souscrire/SouscrireContainer';
import getFormuleChoisie from '../utilsTarif/getFormuleChoisie';
import getPriceByFractionnementOrCodePromo from '../utilsTarif/getPriceByFractionnementOrCodePromo';
import FranchisesInfo from "../Franchises/FranchisesInfo.jsx";
import {Avis} from "@amo/core/components/containers/Avis/Avis.jsx";
import avis1 from "@amo/core/assets/images/icons/clients/avis-1.png";

const TableauGarantiesMobile = (props) => {
    const {
        data,
        goTo,
        vehicule,
        formules,
        fractionnementChoisi,
        formulePreconisee,
        TextOptions,
        nbOptions,
        nameWhitelabel,
        apporteur2,
        IDDevis,
        franchises,
        analyticsSetEvent,
        infoDTA,
        infoVI,
        changeValue
    } = props;
    const formuleChoisie = getFormuleChoisie(data, formules);
    const logos = { 67256: assurland, 100122: lesfurets, 101853: lelynx, 393798: meilleureassurance };

    return (
        <div className={'d-block d-lg-none mt-2 bg-grey'}>
            <div className={'row bg-grey'}>
                <div className={'bg-blue rounded-bottom-medium p-2'}>
                    {apporteur2 ? (
                        <>
                            <div className={'col-8 offset-2 px-4 py-3 bg-white mt-3 rounded shadow'}>
                                {IDDevis && (
                                    <p className={'f-14 text-dark text-start fw-bold'}>
                                        Votre devis : <span className={'m-0 fw-bold'}>N°{IDDevis}</span>
                                    </p>
                                )}
                                <img src={logos[apporteur2.codeBelair]} alt="Logo" className={'pt-2'} width={100} />
                            </div>
                            <p className={'text-white f-17 fw-bold mx-5 mt-3 mb-3'}>
                                Vous êtes au meilleur endroit <br />
                                pour assurer votre <span className={'text-dark'}>{vehicule.brand?.name}</span>...{' '}
                            </p>
                            <p className={'text-white'}>
                                Bonjour, bienvenue chez <span className={'fw-bold'}>{nameWhitelabel}.</span>
                            </p>
                            <p className={'text-white mb-3'}>
                                Comme vous, <span className={'fw-bold'}>500 000 motards</span> nous ont déjà fait
                                confiance.
                            </p>
                            <p className={'text-white'}>
                                Dans 3 clics, vous aurez{' '}
                                <span className={'fw-bold text-dark'}>votre attestation d'assurance&nbsp;!</span>
                            </p>
                        </>
                    ) : (
                        <p className={'text-white f-17 fw-bold mx-5 mt-3 mb-3'}>
                            Choisissez votre formule <br />
                            pour assurer votre véhicule
                        </p>
                    )}

                    <div className={'py-2 px-3 bg-white mt-3 rounded'}>
                        <p className={'f-14 text-dark text-start fw-bold'}>
                            Votre deux-roues{' '}
                            <span
                                className={'text-secondary f-12 float-end underline-link cursor-pointer'}
                                onClick={() => goTo({ step: 0, question: 1 })}
                            >
                                modifier
                            </span>
                        </p>
                        <div className={'row mt-2'}>
                            <div className="col-3">
                                <img src={getIconByType(data?.typeVehicule)} alt="Véhicule" className={'pt-2'} />
                            </div>

                            <div className="col-7 text-start">
                                <p className={'m-0 fw-bold'}>
                                    {vehicule.brand?.name} {vehicule.cylindree}
                                </p>
                                <small>
                                    {vehicule.modele}{' '}
                                    {`${
                                        vehicule.annee_debut_version !== null && vehicule.annee_debut_version !== 0
                                            ? `depuis ${vehicule.annee_debut_version}`
                                            : ''
                                    } ${
                                        vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
                                            ? ` jusqu'à ${vehicule.annee_fin_version}`
                                            : ''
                                    }`}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row bg-grey pt-5 justify-content-between">
                <div className="col-12 d-flex">
                    {formules &&
                        formules?.map((formule, index) => (
                            <div className={'col-4 px-1 position-relative'} key={index}>
                                {
                                    formule.CodeFormule === formulePreconisee && (
                                    <div
                                        className={'rounded-top position-absolute text-white bg-purple py-1 px-2 f-12'}
                                        style={{left: '50%', top: -42, transform: 'translateX(-50%)'}}
                                    >
                                        <i style={{color: '#f7aa36'}} className="fa fa-star" aria-hidden="true"/>
                                        &nbsp;
                                        <i style={{color: '#f7aa36'}} className="fa fa-star" aria-hidden="true"/>
                                        &nbsp;
                                        <i style={{color: '#f7aa36'}} className="fa fa-star" aria-hidden="true"/>
                                       Recommandée
                                  </div>
                        )}
                    <Field
                        component={RadioBtn}
                        type={'radio'}
                        name={'DemandeTarif[Police][FormuleChoisie]'}
                        value={formule.CodeFormule}
                        label={`<b style={{fontSize:10}}>${formule.LibelleFormule.toUpperCase()}<br/><span className='f-16'>${
                            getPriceByFractionnementOrCodePromo(formule, fractionnementChoisi).prix
                        }</span></b>`}
                        className={'button-radio-btn border rounded h-100 position-relative'}
                        onClick={() => analyticsSetEvent({
                            event: 'changementFormule', datas: {
                                'changementFormule': {
                                            'formule' : formule.LibelleFormule.toUpperCase()
                                        }
                                    }})}
                                    onChange={() => {
                                        changeValue(`options`, {});
                                        changeValue(`optionsFranchise`, {RAFRAVI: 'false', RAFRADTA: 'false',});
                                        changeValue(`optionsListe`, {});
                                    }}
                                />
                            </div>
                        ))}
                </div>
            </div>

            {formuleChoisie && (
                <>
                    <div className="row bg-grey p-3">
                        <div id={'detail-formule'} className="col-12 shadow border rounded mt-4 border-disabled">
                            <div className={'row py-4 px-2 bg-lighter rounded-top'}>
                                <div className="col-6 text-start">
                                    <p className={'fw-bold f-14 text-dark'}>Votre formule</p>
                                    <p className={'fw-bold f-20 text-dark'}>
                                        {formuleChoisie.LibelleFormule.toUpperCase()}
                                    </p>
                                    <p className={'fw-bold text-secondary f-14'}>
                                        Hors options
                                    </p>
                                </div>
                                {fractionnementChoisi === 'M' ? (
                                    <div className="col-6 text-end">
                                        <p className={'f-12'}>
                                            <span className={'f-20 fw-bold text-secondary'}>
                                                {normalizeEuro(formuleChoisie.PrimeMensuelleBase)}
                                            </span>{' '}
                                            /mois<sup>*</sup>
                                        </p>
                                        <small className={'f-12'}>
                                            ou{' '}
                                            <span className={'f-14 fw-bold'}>
                                                {normalizeEuro(formuleChoisie.PrimeMensuelleBase * 12)}
                                            </span>{' '}
                                            /an<sup>*</sup>
                                        </small>
                                        <p className={'f-12'}>
                                            <sup>*</sup>Prix TTC
                                        </p>
                                    </div>
                                ) : (
                                    <div className="col-6 text-end">
                                        <p className={'f-12'}>
                                            <span className={'f-20 fw-bold text-secondary'}>
                                                {normalizeEuro(formuleChoisie.PrimeAnnuelleFracAnnuelBase)}
                                            </span>{' '}
                                            /an<sup>*</sup>
                                        </p>
                                        <small className={'f-12'}>
                                            ou{' '}
                                            <span className={'f-14 fw-bold'}>
                                                {normalizeEuro(formuleChoisie.PrimeAnnuelleFracAnnuelBase / 12)}
                                            </span>{' '}
                                            /mois<sup>*</sup>
                                        </small>
                                        <p className={'f-12'}>
                                            <sup>*</sup>Prix TTC
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="row bg-white px-2">
                                <div className="col-12 text-start mt-4">
                                    <p className={'f-16 fw-bold text-dark'}>Vos garanties</p>
                                </div>
                                <div className="col-12 px-4">
                                    {formuleChoisie?.ListeGaranties?.map((garantie, index) => {
                                        return (
                                            garantie.Disponible === 'Inclusion' && (
                                                <div
                                                    className={`row ${index > 0 && 'border-top border-2'} py-4`}
                                                    key={index}
                                                >
                                                    <div className="col-1 text-start ps-0">
                                                        <img src={Inclus} alt="Inclus" className={'align-middle'} />
                                                    </div>
                                                    <div className="col text-start">
                                                        <p className={'f-14 text-dark fw-bold'}>
                                                            {garantie.LibelleGarantie}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="col-2 pe-0 text-end"
                                                        onClick={() =>
                                                            showModal({
                                                                title: garantie.LibelleGarantie,
                                                                icon: 'info',
                                                                html: TextOptions[data?.typeVehicule][
                                                                    garantie.CodeGarantie
                                                                ].texteDeroulant,
                                                            })
                                                        }
                                                    >
                                                        <img src={Informations} alt="?" className={'align-middle'} />
                                                    </div>
                                                </div>
                                            )
                                        );
                                    })}

                                    <Options {...props} />


                                    { franchises.labelGroupe
                                        ? <Franchises {...props} />
                                        : (infoVI[0] && infoDTA[0] && (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible')) &&
                                            <FranchisesInfo {...props}
                                                VI={(infoVI[0] && infoVI[0].Disponible !== 'NonDisponible') ? infoVI[0] : null}
                                                DTA={(infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible') ? infoDTA[0] : null}
                                            />
                                    }

                                    <CodePromoContainer {...props} />
                                </div>
                            </div>
                        </div>
                        <SouscrireContainer formuleChoisie={formuleChoisie} {...props} />
                        <div className={'container mt-3'}>
                            <div className={'row'}>
                                <Avis
                                    name={'Maëla R'}
                                    icon={avis1}
                                    content={'Meilleure assurance qualité prix au niveau moto en tout risques, déjà assuré au niveau mutuelle je suis\n' +
                                        'très satisfaite d\'April en tout point je recommande'}
                                    date={'24/01/2024'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default TableauGarantiesMobile;
