import Carte_Grise from '@amo/core/assets/images/AMO_Icons/Carte_Grise.svg';
import { Question } from '@amo/core/components/containers';

const GoToCoordonnees = (props) => {
    const { nextQuestion } = props;

    return (
        <Question
            icon={Carte_Grise}
            title={"<b>Ca y est</b>, nous avons <b>toutes les informations</b> qu'il nous faut 🎉"}
        >
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-3 f-18'}>Finissons avec vos coordonnées !</p>
                    <p className={'mt-3 f-18'}>
                        Nous en avons besoin pour <b>vous transmettre votre devis personnalisé</b> et{' '}
                        <b>vous accompagner au mieux</b> dans vos démarches.
                    </p>
                    <p className={'mt-3 f-18'}>OK pour vous ?</p>
                </div>
            </div>
            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                C'est parti !
            </button>
        </Question>
    );
};

export default GoToCoordonnees;
