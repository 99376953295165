import tchat from '@amo/core/assets/images/icons/tchat.svg';
import rgpd from '@amo/core/assets/images/logos/logo-rgpd.png';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { validateEmail } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { Field } from 'redux-form';

const EmailClient = (props) => {
    const { nextQuestion, data, changeValue } = props;

    return (
        <Question icon={tchat} title={'Quelle est votre <b>adresse e-mail</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        component={Text}
                        type={'email'}
                        typeFormat={'email'}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        placeholder={'Adresse email'}
                        validate={validateEmail}
                        onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                    />
                    <small className={'text-justify d-block mt-1'}>
                        En nous communiquant votre adresse e-mail, vous acceptez que la gestion et l’exécution de votre
                        contrat soient réalisées par voie dématérialisée : vous pourrez y mettre un terme à tout moment
                        et sans frais en vous rendant sur votre Espace Assuré.
                    </small>
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    (!_.get(data, 'DemandeTarif[ListePersonnes][0][Email]') ||
                        validateEmail(_.get(data, 'DemandeTarif[ListePersonnes][0][Email]'))) &&
                    'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>

            <BulleAide
                icon={rgpd}
                title={'Données personnelles'}
                text={
                    "Les données personnelles que vous renseignez dans ce formulaire sont nécessaires au calcul de votre devis, pour la conclusion et l'exécution du contrat d’assurance deux roues et la prise en charge des garanties qui y sont associées."
                }
            />
        </Question>
    );
};

export default EmailClient;
