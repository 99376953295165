import vehicule from '@amo/core/assets/images/AMO_Icons/SearchVehicule.svg';
import { Question } from '@amo/core/components/containers';

const GoToVehicule = (props) => {
    const { nextQuestion } = props;

    return (
        <Question icon={vehicule} title={'Bonjour !'}>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-10 col-md-12'}>
                    <p className={'mt-5 f-18'}>
                        Pour vous proposer un <span className={'fw-bolder text-secondary'}>devis personnalisé</span>,
                        nous avons besoin de collecter{' '}
                        <span className={'fw-bolder text-secondary'}>
                            des informations sur vous et votre deux-roues.
                        </span>
                    </p>

                    <p className={'mt-3 f-18'}>
                        Commençons d'abord par quelques questions <b>concernant votre véhicule !</b>
                    </p>

                    <p className={'mt-3 f-18'}>Avant de commencer, munissez-vous de :</p>
                    <ul className={'text-start mt-3 ms-5'}>
                        <li>Votre permis de conduire</li>
                        <li>
                            La carte grise du véhicule à assurer <small>(si vous l'avez déjà)</small>
                        </li>
                        <li>
                            Le relevé d’informations de votre assureur <small>(si vous l’avez également)</small>
                        </li>
                    </ul>

                    <p className={'f-26 fw-bold text-secondary'}>Vous êtes prêt ?</p>
                </div>
            </div>

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => nextQuestion()}>
                C'est parti !
            </button>
        </Question>
    );
};

export default GoToVehicule;
