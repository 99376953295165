import { connect } from 'react-redux';
import { getApporteur1Init } from '../../../../../../redux/selectors/init/init.selectors';
import LieuStationnement from './LieuStationnement';

const mapStateToProps = (state) => {
    return {
        apporteur1: getApporteur1Init(state),
    };
};

const LieuStationnementContainer = connect(mapStateToProps)(LieuStationnement);

export default LieuStationnementContainer;
