import Calendar from '@amo/core/assets/images/AMO_Icons/Calendar.svg';
import info from '@amo/core/assets/images/icons/info.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { DateField } from '@amo/core/components/forms';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import {showModal} from "@amo/core/utils/index.js";
import carte_grise from "@amo/core/assets/images/bulle/cartegrisedateachat.jpg";
import InformationsBleu from "@amo/core/assets/images/icons/informations-bleu.png";

const DateAchat = (props) => {
    const { nextQuestion, data, changeValue } = props;

    const isNotValid = () => {
        const date = _.get(data, 'DemandeTarif[Vehicule][DateAchatVehicule]');

        return !moment(date, 'DD/MM/YYYY', true).isValid();
    };

    return (
        <Question
            icon={Calendar}
            title={'Quelle est la <b>date</b> <b>d\'acquisition<br/> </b> de votre <b>véhicule</b>&nbsp;?'}
        >
            <div className={'row justify-content-center mt-2'}>
                <p
                    className={'f-12 text-secondary mb-3'}
                    onClick={() =>
                        showModal({
                            imageUrl: carte_grise,
                            html: `<p>La date d’acquisition est renseignée en case <strong>I</strong> de la carte grise.</p>`,
                        })
                    }
                >
                    <img src={InformationsBleu} alt="?" className={'align-middle'}/>{' '}
                    <span className={'pb-1 cursor-pointer underline-link'}>
                        Où trouver la date d'acquisition&nbsp;?
                    </span>
                </p>
            </div>
            <BulleAide
                icon={info}
                title={"Comment renseigner la date d’acquisition&nbsp;?"}
                text={
                    "La date d'acquisition correspond à la date à laquelle vous avez pris possession du véhicule.<br/>Si vous n’avez pas encore pris possession de votre véhicule, indiquez la date d’aujourd’hui."
                }
                className={'col-lg-6 offset-lg-3 col-md-12 mb-4 mt-3'}
            />

            <div className={'row justify-content-center'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        name={'DemandeTarif[Vehicule][DateAchatVehicule]'}
                        component={DateField}
                        placeholder={'Exemple : 22/03/2020'}
                        minDate={moment('01/01/1990')}
                    />
                </div>
            </div>

            <button
                className={`btn btn-primary btn-arrow mt-4 ${isNotValid() && 'disabled'}`}
                onClick={() => {
                    if (
                        moment(_.get(data, 'DemandeTarif[Vehicule][DateAchatVehicule]'), 'DD/MM/YYYY', true) >=
                        moment().startOf('day').subtract(3, 'months')
                    ) {
                        changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '0');
                        changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
                    } else {
                        changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '1');
                        changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '');
                    }
                    nextQuestion();
                }}
            >
                Continuer
            </button>
        </Question>
    );
};

export default DateAchat;
