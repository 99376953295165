import _ from 'lodash';
import moment from 'moment';
import ConnexionClientContainer from './ConnexionClient/ConnexionClientContainer';
import CylindreeVehiculeContainer from './CylindreeVehicule/CylindreeVehiculeContainer';
import DateMec from './DateMEC/DateMEC';
import GoToProfil from './GoToProfil/GoToProfil';
import GoToVehicule from './GoToVehicule/GoToVehicule';
import LieuStationnementContainer from './LieuStationnement/LieuStationnementContainer';
import MarqueVehiculeContainer from './MarqueVehicule/MarqueVehiculeContainer';
import ModeleVehiculeContainer from './ModeleVehicule/ModeleVehiculeContainer';
import TypeVehicule from './TypeVehicule/TypeVehicule';
import {UsageVehicule, UsageNonLivraison,
    VehiculeImmatricule, Assure3DerniersMois,
    CarteGriseFrancaise, ValeurVehicule, PossedeVehiculePlus3Mois
} from "@amo/core/components/questions";
import DateAchat from "./DateAchat/DateAchat";

const Vehicule = (props) => {
    const { data, id, nameWhitelabel } = props;

    return (
        <div id={id}>
            <GoToVehicule {...props} />

            {/*en attente api immat*/}
            {/*<SearchVehiculeByImmatOrModele {...props} />
            { data?.searchBy === "immatriculation" && <SearchByImmat {...props} /> }*/}

            {/*{
                data?.searchBy === "modele" && <>*/}
            <TypeVehicule {...props} />
            {data?.typeVehicule && <MarqueVehiculeContainer {...props} />}
            {data?.marqueVehicule && <CylindreeVehiculeContainer {...props} />}
            {data?.cylindreeVehicule && <ModeleVehiculeContainer {...props} />}
            {/*</>
            }*/}

            <DateMec {...props} />

            <DateAchat {...props} />

            {/*{nameWhitelabel === 'APRIL Moto' && <DejaClient {...props} />}*/}

            {_.get(data, 'DemandeTarif[ListePersonnes][0][DejaClient]') === '1' && (
                <ConnexionClientContainer {...props} />
            )}

            {/* champs caché depuis ajout dateAchat moment(_.get(data, 'DemandeTarif[Vehicule][DateMEC]'), 'DD/MM/YYYY', true) <=
                moment().subtract(3, 'months') && <PossedeVehiculePlus3Mois {...props} />
            */}

            {_.get(data, 'DemandeTarif[Vehicule][PossessionPlusDe3Mois]') === '1' && <Assure3DerniersMois {...props} />}

            {_.includes(['quad', 'verte'], data?.typeVehicule) && (
                <>
                    {/*<ValeurVehicule title={'Quelle est <b>la valeur</b> de votre <b>véhicule</b>&nbsp;?'} {...props} />*/}
                    <VehiculeImmatricule title={'Votre véhicule est-il <b>immatriculé</b>&nbsp;?'} {...props} />
                </>
            )}

            {_.get(data, 'DemandeTarif[Vehicule][VehiculeImmatricule]') === '1' && <CarteGriseFrancaise {...props} />}

            {!_.includes(['quad', 'verte'], data?.typeVehicule) &&
                <UsageVehicule {...props} title={'A quelle(s) <b>occasion(s)</b> <br/>utilisez-vous <b>votre véhicule</b>&nbsp;?'} />
            }

            {data?.cylindreeVehicule === '50' && <UsageNonLivraison {...props} />}

            <LieuStationnementContainer {...props} />

            <GoToProfil {...props} />
        </div>
    );
};

export default Vehicule;
