import { API_URL_WS_FORMULAIRE, APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { POST_PAY, postPay } from '../../../../../redux/actions/app/pay/pay.action';
import { getFractionnementPoliceDemandeTarifInit, getToken } from '../../../../../redux/selectors/init/init.selectors';
import { getListeFormulesTarif } from '../../../../../redux/selectors/tarif/tarif.selectors';
import { getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors';
import { getVehicule } from '../../../../../redux/selectors/vehicule/vehicule.selectors';
import Paiement from './Paiement';

const mapStateToProps = (state) => {
    return {
        vehicule: getVehicule(state),
        formules: getListeFormulesTarif(state),
        fractionnementChoisi: getFractionnementPoliceDemandeTarifInit(state),
        query: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
        url_pay: {
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}`,
        },
        payLoaded: getLoadingEntity(state, POST_PAY),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postPay,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { url_pay, query } = stateProps;
    const { postPay } = dispatchProps;
    const { data } = ownProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postPaySubmit: () => postPay({ form: data, query: query, url_pay }),
    };
};

const PaiementContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Paiement);

export default PaiementContainer;
