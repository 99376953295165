import NonInclus from '@amo/core/assets/images/icons/non_inclus.svg';
import { useOnClickOutside } from '@amo/core/utils/hooks.js';
import { useRef, useState } from 'react';
import ChoixRappel from './ChoixRappel/ChoixRappel';
import Coordonnees from './Coordonnees/Coordonnees';
import Creneaux from './Creneaux/Creneaux';

const Booking = (props) => {
    const { setShowBooking } = props;
    const [stepForm, setStepForm] = useState(1);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowBooking(false));

    return (
        <div
            style={{ background: 'rgba(0,0,0,.4)', zIndex: 2 }}
            className={'modal-booking position-absolute bottom-0 top-0 start-0 end-0 p-3'}
        >
            <div
                className={'bg-white p-lg-4 p-3 rounded shadow text-center'}
                style={{ width: '65em', maxWidth: '100%', margin: '0 auto' }}
                ref={ref}
            >
                <img
                    src={NonInclus}
                    alt="Close"
                    className={'align-middle position-absolute cursor-pointer'}
                    style={{ right: 20, top: 20 }}
                    onClick={() => setShowBooking(false)}
                />
                {stepForm === 1 && <Coordonnees setStepForm={setStepForm} {...props} />}
                {stepForm === 2 && <ChoixRappel setStepForm={setStepForm} {...props} />}
                {stepForm === 3 && <Creneaux setStepForm={setStepForm} {...props} />}
            </div>
        </div>
    );
};

export default Booking;
