import { connect } from 'react-redux';
import { getDocumentsUrlInit } from '../../../../../redux/selectors/init/init.selectors';
import EnvoiPieces from './EnvoiPieces';

const mapStateToProps = (state) => {
    return {
        documentsUrl: getDocumentsUrlInit(state),
    };
};

const EnvoiPiecesContainer = connect(mapStateToProps)(EnvoiPieces);

export default EnvoiPiecesContainer;
