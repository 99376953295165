import { useOnClickOutside } from '@amo/core/utils/hooks.js';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

const PopinOpinionsAssurances = (props) => {
    const { data, setShowPopin } = props;
    const ref = useRef();
    useOnClickOutside(ref, () => setShowPopin(false));

    useEffect(() => {
        window.OaP = {
            CarrierId: 268,
            ProductTypeId: 2,
            UserLogin: '',
            ContactEmail: _.get(data, 'DemandeTarif[ListePersonnes][0][Email]') ?? '',
            ContactFirstName: _.get(data, 'DemandeTarif[ListePersonnes][0][Prenom]') ?? '',
            ContactLastName: _.get(data, 'DemandeTarif[ListePersonnes][0][Nom]') ?? '',
            ContactPhone: _.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]')
                ? _.replace(_.get(data, 'DemandeTarif[ListePersonnes][0][TelPortable]'), /\s/g, '')
                : '',
            IsCarrierContactAccept: _.get(data, 'DemandeDevis[OptinNewsletter]') ?? false,
            CarrierExternalId: '',
        };

        const script = document.createElement('script');
        const div = document.getElementById('divOa');

        script.src = 'https://d2x33sw8v4ayed.cloudfront.net/resource/js/plugin.js';
        script.setAttribute('name', '04ebcc52a7146f9a90f318fac1f15540');
        script.setAttribute('id', 'OpinionAssurancesPlugin');

        div.appendChild(script);
    }, []);

    return (
        <div
            style={{ background: 'rgba(0,0,0,.4)', zIndex: 90 }}
            className={'position-fixed bottom-0 top-0 start-0 end-0 p-2'}
        >
            <div
                className={'bg-white p-4 pb-2'}
                style={{
                    height: '98vh',
                    borderRadius: 20,
                    boxShadow: '0px 14px 50px rgb(0 0 0 / 15%)',
                    width: '54em',
                    maxWidth: '100%',
                    margin: '0 auto',
                }}
                ref={ref}
                id="divOa"
            ></div>
        </div>
    );
};

export default PopinOpinionsAssurances;
