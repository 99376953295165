const MessageNomPrenom = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mb-2'}>
                <div className="alert alert-warning mt-3" role="alert">
                    <p className="mb-0">Votre nom et prénom sont identiques.<br/>Merci de vérifier
                        vos informations avant de continuer.</p>
                </div>
            </div>
        </div>
    );
};

export default MessageNomPrenom;
