import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';

const TiersPayeurBirthName = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={`DemandeContrat[TiersPayeur][BirthName]`}
                    label={'Nom de naissance'}
                    component={Text}
                    normalize={(v) => v.toUpperCase()}
                    type={'text'}
                    maxLength={20}
                />
            </div>
        </div>
    );
};

export default TiersPayeurBirthName;
