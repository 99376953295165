import _ from 'lodash';
import RouteStep from '../components/App/Init/RouteStep';

export const routesAffichees = (routes) => {
    let cloneRoute = _.cloneDeep(routes);
    cloneRoute = _.reject(cloneRoute, ['slug', 'recontact']);
    cloneRoute = _.reject(cloneRoute, ['slug', 'faq']);
    return cloneRoute;
};

export const getStepBySlug = (slug) => {
    const route = _.find(RouteStep, ['slug', slug]);
    return route && route !== -1 ? route.step : '';
};

export const getSlugByStep = (step) => {
    const route = _.find(RouteStep, ['step', step]);
    return route && route !== -1 ? route.slug : '';
};

export const getTitleByStep = (step) => {
    const route = _.find(RouteStep, ['step', step]);
    return route && route !== -1 ? route.title : '';
};
