import { Text } from '@amo/core/components/forms';
import { Field } from 'redux-form';

const TiersPayeurLastName = (tiersPayeurType) => {
    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    name={`DemandeContrat[TiersPayeur][LastName]`}
                    label={tiersPayeurType?.tiersPayeurType === 'P' ? "Nom d'usage" : 'Nom de la société'}
                    component={Text}
                    type={'text'}
                    maxLength={50}
                />
            </div>
        </div>
    );
};

export default TiersPayeurLastName;
