import { API_URL_WS_FORMULAIRE } from '@amo/core/constants/constants.js';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector, getFormValues } from 'redux-form';
import { POST_DEJA_CLIENT, postDejaClient } from '../../../../../../redux/actions/app/dejaClient/dejaClient.actions';
import { getToken } from '../../../../../../redux/selectors/init/init.selectors';
import { getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import ConnexionClient from './ConnexionClient';

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis-direct');

    return {
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        data: getFormValues('devis-direct')(state),
        idDejaClient: selector(state, 'DemandeTarif[ListePersonnes][0][NumeroClient]'),
        nomDejaClient: selector(state, 'DemandeTarif[ListePersonnes][0][Nom]'),
        loading: getLoadingEntity(state, POST_DEJA_CLIENT),
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            postDejaClient,
        },
        dispatch,
    );

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postDejaClient } = dispatchProps;
    const { data, query } = stateProps;
    const { nextQuestion } = ownProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        postDejaClient: () => postDejaClient({ data, query, nextQuestion }),
    };
};

const ConnexionClientContainer = compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(ConnexionClient);

export default ConnexionClientContainer;
