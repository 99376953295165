import './question.scss';

export const Question = (props) => {
    const { icon, title, subtitle, position, children, loader } = props;

    return (
        <div className={`${!loader && 'question'} rounded text-center col-lg-8 offset-lg-2 col-md-12`}>
            <div className={'p-3'}>
                {icon && <img src={icon} alt={title} className={'mx-2'} width={65} />}
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className={`${position === 'top' ? 'flex-column' : 'flex-column-reverse'} d-flex`}>
                            <p className={'question-title mx-2'} data-title={title.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, ' ')} dangerouslySetInnerHTML={{ __html: title }} />
                            <p className={'f-16 text-secondary mx-2 fw-bolder'}>{subtitle}</p>
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

Question.defaultProps = {
    children: <p>Children Question</p>,
};
