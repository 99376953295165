import Carte_Grise from '@amo/core/assets/images/AMO_Icons/Carte_Grise.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import TitulaireMajeur from './TitulaireMajeur/TitulaireMajeur';
import TitulaireMineur from './TitulaireMineur/TitulaireMineur';

const TitulaireCarteGrise = (props) => {
    const { nextQuestion, data } = props;

    const souscripteurIsMineur = (data) => {
        return (
            moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') >=
            moment()
        );
    };

    return (
        <Question icon={Carte_Grise} title={'À quel <b>nom</b> est la <b>carte grise du véhicule</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                {souscripteurIsMineur(data) ? <TitulaireMineur {...props} /> : <TitulaireMajeur {...props} />}
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={'titulaireCarteGrise'}
                        value={'autres'}
                        label={"A quelqu'un d'autre"}
                    />
                </div>
            </div>

            {_.get(data, 'titulaireCarteGrise') === 'autres' && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    Le souscripteur doit être titulaire de la carte grise.
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    (!_.find(_.get(data, 'DemandeTarif[ListePersonnes]'), ['Souscripteur', true]) ||
                        _.get(data, 'titulaireCarteGrise') === 'autres' ||
                        !_.get(data, 'titulaireCarteGrise')) &&
                    'disabled'
                }`}
                onClick={() => nextQuestion()}
            >
                Continuer
            </button>
        </Question>
    );
};

export default TitulaireCarteGrise;
