import { useEffect } from 'react';

const Ekomi = (props) => {
    const { idDevis } = props;

    useEffect(() => {
        (function (w) {
            w['_ekomiServerUrl'] = 'https://smartforms.ekomi.com';
            w['_ekomiShopId'] = '65792';
            w['_ekomiFormId'] = '36787';
            w['_ekomiTransactionId'] = idDevis;
            w['_ekomiWidgetWidth'] = '1020px';
            w['_ekomiWidgetHeight'] = '1290px';
            w['_ekomiEmbedWidget'] = '0';
            w['_ekomiDisableAutoClose'] = '0';
            w['_ekomiEmail'] = '';
            var s = document.createElement('script');
            s.src = w['_ekomiServerUrl'] + '/script/widget.js?v=' + new Date().getTime();
            s.async = true;
            var e = document.getElementsByTagName('script')[0];
            e.parentNode.insertBefore(s, e);
        })(window);
    }, []);
    return <div id="sff_127__widget-container" />;
};

export default Ekomi;
