import { Select } from '@amo/core/components/forms';
import _ from 'lodash';
import { useEffect } from 'react';
import { Field } from 'redux-form';

const TiersPayeurRelationship = (props) => {
    const { data, changeValue } = props;

    useEffect(() => {
        if (_.get(data, 'DemandeContrat[TiersPayeur][Relationship]') !== 'A') {
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '');
        }
    }, [_.get(data, 'DemandeContrat[TiersPayeur][Relationship]')]);

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-6 col-12 my-2'}>
                <Field
                    component={Select}
                    label={'Lien  souscripteur / payeur'}
                    name={`DemandeContrat[TiersPayeur][Relationship]`}
                >
                    <option value={''}>Sélectionnez...</option>
                    <option value="C">Conjoint</option>
                    <option value="E">Enfant</option>
                    <option value="FS">Frère/Sœur</option>
                    <option value="GP">Grand-parent</option>
                    <option value="P">Parent</option>
                    <option value="A">Autres</option>
                </Field>
            </div>
        </div>
    );
};

export default TiersPayeurRelationship;
