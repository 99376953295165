import { PostalCodeCity, CAPAdresse } from '@amo/core/components/forms';
import { excludeCodePostal } from '@amo/core/utils/validateField';
import _ from 'lodash';

const Adresse = (props) => {
    const { data, changeValue, setHasSearchAddress } = props;

    return (
        <div>
            <div className={'row justify-content-center'}>
                <div className={'col-lg-8 col-md-6 col-sm-6 col-12 mt-2'}>
                    <PostalCodeCity
                        label={'Code postal & Ville'}
                        required
                        id={'codepostal'}
                        nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                        nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                        validate={excludeCodePostal}
                        placeholder={'Exemple : 75011 PARIS'}
                        changeValue={changeValue}
                        defaultValue={
                            _.get(data, 'DemandeTarif[ListePersonnes][0][CP]') &&
                            `${_.get(data, 'DemandeTarif[ListePersonnes][0][CP]')} ${_.get(
                                data,
                                'DemandeTarif[ListePersonnes][0][Ville]',
                            )}`
                        }
                    />
                </div>

                <div className="row justify-content-center">
                    <CAPAdresse changeValue={changeValue} setHasSearchAddress={setHasSearchAddress} data={data}/>
                </div>

            </div>
        </div>
    );
};

export default Adresse;
