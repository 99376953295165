import {Radio} from '@amo/core/components/forms';
import moment from 'moment';
import { Field } from 'redux-form';

const LoiHamon = (props) => {
    const { changeValue } = props;

    return (
        <>
            <div className={'row justify-content-center'}>
                <h3 className={'f-16 text-center fw-bold mt-3'}>Souhaitez-vous que l’on résilie pour vous votre contrat actuel dans le cadre de la&nbsp;Loi&nbsp;Hamon&nbsp;?
                    <sup className={'text-danger'}>*</sup></h3>
            </div>

            <div className={'row justify-content-center'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeContrat[DevisHamon]`}
                        value={'1'}
                        label={'Oui'}
                        className={'small'}
                        onChange={() =>
                            changeValue(
                                'DemandeContrat[DateHeureEffet]',
                                moment().add(33, 'days').add(5, 'minutes').format('DD/MM/YYYY HH:mm'),
                            )
                        }
                    />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field
                        component={Radio}
                        type={'radio'}
                        name={`DemandeContrat[DevisHamon]`}
                        value={'0'}
                        label={'Non'}
                        className={'small'}
                    />
                </div>
            </div>
        </>
    );
};

export default LoiHamon;
