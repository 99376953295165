import './navbar.scss';

const NavBar = (props) => {
    const { indexQuestion, activeStep, nbQuestions, routes } = props;
    const pourcentage = Math.round((Number(indexQuestion + 1) / Number(nbQuestions + 1)) * 100);

    return (
        <div className={'nav stepper'}>
            {routes.slice(0, 4).map((s, i) => (
                <div
                    className={`step col ${s.step === activeStep ? 'active' : ''} ${
                        s.step < activeStep ? 'past d-none d-lg-block' : ''
                    } ${s.step > activeStep ? 'disabled d-none d-lg-block' : ''}`}
                    key={i}
                >
                    {s.step <= activeStep ? (
                        <p>
                            <a href={s.path}>{s.title}</a>
                        </p>
                    ) : (
                        <p>{s.title}</p>
                    )}
                    <div className="progress">
                        {s.step === activeStep && (
                            <div
                                className="progress-bar bg-info"
                                role="progressbar"
                                style={{ width: pourcentage + '%' }}
                            />
                        )}
                        {s.step > activeStep && (
                            <div className="progress-bar bg-info" role="progressbar" style={{ width: '0%' }} />
                        )}
                        {s.step < activeStep && (
                            <div className="progress-bar bg-info" role="progressbar" style={{ width: '100%' }} />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default NavBar;
